import { Fragment, useEffect } from "react";
import { useState } from "react";
import ContentEditable from "react-contenteditable";
import objectId from "../../shared/objectId";
import setCaretToEnd from "../../shared/setCaretToEnd";
import usePrevious from "../../shared/usePrevious";
import Block from "../Block";
import { ArrowUpOnSquareIcon, Bars3Icon, BookmarkIcon, CheckCircleIcon, EllipsisHorizontalIcon, PlusIcon, ShareIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { PencilIcon, SparklesIcon } from '@heroicons/react/24/solid';
import { useParams } from "react-router-dom";
import { api } from "../../shared/api";
import { getAuth } from "firebase/auth";
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import ShareModal from "../ShareModal";
import { flags } from "../../shared/flags";
import setCaretToIndex from "../../shared/setCaretToIndex";
import OriginalityPanel from "../OriginalityPanel";
import { appName } from "../../shared/appName";
import ChatPanel from "../ChatPanel";


const Document = (props) => {



    const initialId = objectId();

    const [modals, setModals] = useState({
        share: false
    })

    const [completeEnabled, setCompleteEnabled] = useState(true);

    const [title, setTitle] = useState("");
    const [blocks, setBlocks] = useState([{
        id: initialId,
        tag: "p",
        html: ""
    }])
    const [citations, setCitations] = useState([]);
    const [doc, setDoc] = useState();
    const [webVisibility, setWebVisibility] = useState("none");
    const [currentBlockId, setCurrentBlockId] = useState(initialId);
    const [initialized, setInitialized] = useState(false);
    const [loadedDoc, setLoadedDoc] = useState(false);
    const [error, setError] = useState(false);

    const [openWindow, setOpenWindow] = useState();
    const [topic, setTopic] = useState()
    const [loadingInitialCompletion, setLoadingInitialCompletion] = useState(false);

    const [doNotFocus, setDoNotFocus] = useState(false);
    const [focusBlock, setFocusBlock] = useState();
    const [merge, setMerge] = useState(false);

    const [selectedBlocks, setSelectedBlocks] = useState([]);

    const prevBlocks = usePrevious(blocks);

    const [history, setHistory] = useState([

    ])

    const [redoHistory, setRedoHistory] = useState([])

    const [caretOffset, setCaretOffset] = useState(0)

    const [isTyping, setIsTyping] = useState(false);
    const [isTypingForHistory, setIsTypingForHistory] = useState(false);

    const [isRedoing, setIsRedoing] = useState(false);


    const auth = getAuth();

    const [panelOpen, setPanelOpen] = useState();
    const [floatingMenuOpen, setFloatingMenuOpen] = useState(false);


    useEffect(() => {
        console.log("Selected blocks: ", selectedBlocks.length)
    }, [selectedBlocks])

    const redo = () => {
        if (redoHistory.length > 0) {
            console.log("Previous redo history", redoHistory)
            const lastHistory = redoHistory[redoHistory.length - 1];
            console.log("Last redo history", lastHistory)
            setIsRedoing(true)
            setBlocks(lastHistory.blocks);
            setFocusBlock(lastHistory.focusBlock);
            setCurrentBlockId(lastHistory.currentBlockId);



            let newHistory = [...redoHistory];

            newHistory.pop();



            console.log("New history", newHistory)


            setRedoHistory(newHistory);
            focusBlockAtIndex(blocks.map((b) => b.id).indexOf(lastHistory.currentBlockId) + 1, lastHistory.caretOffset, "redo")
            console.log("redo")
        }
    }


    const undo = () => {
        if (history.length > 1) {
            console.log("Previous history", history)
            const lastHistory = history[history.length - 2];
            console.log("Last history", lastHistory)
            setMerge(true)
            setBlocks(lastHistory.blocks);
            setFocusBlock(lastHistory.focusBlock);
            setCurrentBlockId(lastHistory.currentBlockId);

            let newRedoHistory = [...redoHistory];
            newRedoHistory.push(history[history.length - 1])
            setRedoHistory(newRedoHistory)


            let newHistory = [...history];

            newHistory.pop();
            newHistory.pop();

            if (newHistory.length == 0) {
                newHistory.push(lastHistory)
            }

            console.log("New history", newHistory)


            setHistory(newHistory);
            focusBlockAtIndex(blocks.map((b) => b.id).indexOf(lastHistory.currentBlockId) + 1, lastHistory.caretOffset)
            console.log("undo")
        }
    }


    const handleKeyDown = (e) => {
        if ((e.key === 'c' || e.key === 'C') && (e.ctrlKey || e.metaKey)) {

            console.log("Blocks to copy: ", selectedBlocks.length)

            if (selectedBlocks.length > 0) {
                console.log("Synthetic copy")
                e.preventDefault();

                let selectedBlockObjects = [];
                let copy = "";

                for (let block of selectedBlocks) {
                    const position = blocks.map((b) => b.id).indexOf(block) + 1;

                    selectedBlockObjects.push({ ...blocks.find(obj => obj.id === block), position: position });
                }

                console.log("Selected block objects: ", selectedBlockObjects)

                for (let block of [...selectedBlockObjects].sort((a, b) => a.position - b.position)) {
                    copy = copy + block.html.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ") + "\n"
                }

                navigator.clipboard.writeText(copy);
            }
        }

        else if ((e.key === 'z' || e.key === 'Z') && (e.shiftKey) && (e.ctrlKey || e.metaKey)) {

            e.preventDefault();
            redo();

        }
        else if ((e.key === 'z' || e.key === 'Z') && (e.ctrlKey || e.metaKey)) {

            e.preventDefault();
            undo();

        }
        else if ((e.key === 'a' || e.key === 'A') && (e.ctrlKey || e.metaKey)) {
            if (!openWindow) {
                e.preventDefault();
                let newSelectedBlocks = [];
                blocks.map(block => {
                    newSelectedBlocks.push(block.id)
                })
                setSelectedBlocks(newSelectedBlocks)
            }
        }
        else if (e.key === 'Backspace') {
            // Remove all selected blocks from blocks array
            if (selectedBlocks.length > 0 && props.signedIn && (doc && doc.ownerId == auth.currentUser.uid)) {
                e.preventDefault();


            let newHistory = [...history];

            const historyEntry = {
                blocks: blocks,
                focusBlock: focusBlock,
                currentBlockId: currentBlockId,
                caretOffset: caretOffset
            }
            console.log("Logged to history", historyEntry)
            console.log("History:", newHistory)
            newHistory.push(historyEntry)
            setHistory(newHistory);

                const newBlock = {
                    id: objectId(),
                    tag: "p",
                    html: ""
                }


                let newBlocks = [...blocks];
                selectedBlocks.map(block => {
                    newBlocks = newBlocks.filter(obj => obj.id !== block)
                })

                if (newBlocks.length == 0) {
                    newBlocks.push(newBlock)
                    setBlocks(newBlocks);
                    setCurrentBlockId(newBlock.id)

                    const block = document.querySelector(
                        `[data-position="1"]`
                    );
                    if (block) {

                        block.focus()
                    }
                } else {
                    setBlocks(newBlocks);
                }

                setSelectedBlocks([])



            }
        }

    }

    useEffect(() => {

        const handleMouseDown = () => {
            setSelectedBlocks([])
        }



        window.addEventListener("mousedown", handleMouseDown);
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("mousedown", handleMouseDown);
            window.removeEventListener("keydown", handleKeyDown);

        };
    }, [selectedBlocks, history, blocks, isTyping]);

    const getDocument = (token) => {
        fetch(api + "/ai/document/" + props.docId + "/?token=" + (auth.currentUser ? token : "none"), {
            method: 'GET',
        })
            .then(response => response.json()).then(res => {
                if (res.success) {
                    if (res.blocks.length > 0) {
                        setBlocks(res.blocks)
                    }
                    setCitations(res.citations)
                    setTitle(res.title)
                    setWebVisibility(res.webVisibility)
                    setDoc(res);
                    console.log(res.webVisibility)
                    setLoadedDoc(true)
                    console.log("loaded doc!")
                } else {
                    setError(true)
                }

            }).catch(err => {
                console.log(err)
                setError(true)

            })
    }

    useEffect(() => {
        setError(false);
        if (props.docId) {
            if (auth.currentUser) {
                auth.currentUser.getIdToken(/* forceRefresh */ true).then(token => {
                    getDocument(token)
                }).catch(err => {
                    console.log(err)
                    setError(true)
                })
            } else {
                getDocument()
            }
        }
    }, [auth.currentUser])

    const updateDocument = () => {
        auth.currentUser.getIdToken(/* forceRefresh */ true).then(token => {
            fetch(api + "/ai/update-document/" + props.docId + "/?token=" + (auth.currentUser ? token : "none"), {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    blocks: blocks,
                    title: title,
                    citations: citations
                })
            })
                .then(response => response.json()).then(res => {
                    console.log("Saved!", blocks)

                }).catch(err => {
                    console.log(err)

                })
        })
    }

    useEffect(() => {
        if (loadedDoc && auth.currentUser && !loadingInitialCompletion) {
           // updateDocument();
        }

    }, [blocks, citations])

    // Handling the cursor and focus on adding and deleting blocks
    useEffect(() => {
        // If a new block was added, move the caret to it
        if (prevBlocks && prevBlocks.length + 1 === blocks.length) {
            const nextBlockPosition =
                blocks.map((b) => b.id).indexOf(currentBlockId) + 1;
            const nextBlock = document.querySelector(
                `[data-position="${nextBlockPosition}"]`
            );
            console.log("Next block", `[data-position="${nextBlockPosition}"]`)
            if (nextBlock) {
                if (!doNotFocus) {
                    nextBlock.focus();
                } else {
                    setDoNotFocus(false);
                }
            }
        }
        // If a block was deleted, move the caret to the end of the last block
        if (prevBlocks && prevBlocks.length - 1 === blocks.length) {

            console.log("block was deleted")

            if (focusBlock) {
                const focusBlockPosition = prevBlocks
                    .map((b) => b.id)
                    .indexOf(focusBlock);
                const lastBlock = document.querySelector(
                    `[data-position="${focusBlockPosition}"]`
                );
                if (lastBlock) {
                    console.log("Last block length", lastBlock.innerHTML.length)

                    setCaretToIndex(lastBlock, lastBlock.innerHTML.length);
                    lastBlock.focus()
                }
            } else {
                const lastBlockPosition = prevBlocks
                    .map((b) => b.id)
                    .indexOf(currentBlockId);
                const lastBlock = document.querySelector(
                    `[data-position="${lastBlockPosition}"]`
                );
                if (lastBlock) {
                    console.log("Last block length", lastBlock.innerHTML.length)
                    setCaretToIndex(lastBlock, lastBlock.innerHTML.length);
                    lastBlock.focus()
                }
            }
        }
    }, [blocks, prevBlocks, currentBlockId, doNotFocus]);

    useEffect(() => {
        if (!initialized) {
            setInitialized(true)
        }
    }, [])

    // log to history
    useEffect(() => {
        if (!isTypingForHistory && loadedDoc && auth.currentUser) {


            let newHistory = [...history];

            const historyEntry = {
                blocks: blocks,
                focusBlock: focusBlock,
                currentBlockId: currentBlockId,
                caretOffset: caretOffset
            }
            console.log("Logged to history", historyEntry)
            console.log("History:", newHistory)
            newHistory.push(historyEntry)
            setHistory(newHistory);

            updateDocument();


        }
    }, [isTypingForHistory, loadedDoc, auth.currentUser])

    const addBlock = (currentBlock, newBlock, offset) => {
        const index = blocks.map((b) => b.id).indexOf(currentBlock.id) + (offset ? offset : 0);
        const updatedBlocks = [...blocks];
        updatedBlocks.splice(index + 1, 0, newBlock);
        updatedBlocks[index] = {
            ...updatedBlocks[index],
            tag: currentBlock.tag,
            html: currentBlock.html,
        };
        setBlocks(updatedBlocks);
        setCurrentBlockId(newBlock.id)
        console.log(JSON.stringify(newBlock))
        console.log(updatedBlocks)
        if (offset == -1) {
            setDoNotFocus(true)
        }
    }

    const updateBlock = (currentBlock) => {
        const index = blocks.map((b) => b.id).indexOf(currentBlock.id);
        const updatedBlocks = [...blocks];
        updatedBlocks[index] = {
            ...updatedBlocks[index],
            html: currentBlock.html,
            tag: currentBlock.tag
        };
        setBlocks(updatedBlocks);
        console.log("updated block", updatedBlocks)
    }

    const deleteBlock = (currentBlock, offset) => {
        if (blocks.length > 1) {
            if (offset == -1) {
                setCurrentBlockId(currentBlock.id);
                setBlocks((prevBlocks) => {
                    const index = prevBlocks.map((b) => b.id).indexOf(currentBlock.id) - 1;
                    const updatedBlocks = [...prevBlocks];
                    updatedBlocks[index] = {
                        ...updatedBlocks[index],
                        id: updatedBlocks[index].id,
                        html: updatedBlocks[index].html + currentBlock.html
                    };
                    updatedBlocks.splice(index + 1, 1);
                    setMerge(true)



                    return updatedBlocks;
                });
                setFocusBlock((prevFocusBlock) => prevFocusBlock);
            } else {
                setCurrentBlockId(currentBlock.id);
                setBlocks((prevBlocks) => {
                    const index = prevBlocks.map((b) => b.id).indexOf(currentBlock.id);
                    const updatedBlocks = [...prevBlocks];
                    updatedBlocks.splice(index, 1);


                    return updatedBlocks;
                });
                setFocusBlock((prevFocusBlock) => prevFocusBlock - 1);
            }

        }
    };

    const focusBlockAtIndex = (blockPosition, index, source) => {
        const block = document.querySelector(
            `[data-position="${blockPosition}"]`
        );
        if (block) {
            setCaretToIndex(block, index, source);
            block.focus()
        }
    }

    const deleteDocument = () => {
        auth.currentUser.getIdToken(/* forceRefresh */ true).then(token => {
            fetch(api + "/ai/delete-document/" + props.docId + "/?token=" + (auth.currentUser ? token : "none"), {
                method: 'POST',
            })
                .then(response => response.json()).then(res => {
                    if (res.success) {

                        window.location.href = "/"
                    }

                }).catch(err => {
                    console.log(err)


                })
        }).catch(err => {
            console.log(err)

        })
    }

    const createPlan = () => {
        setLoadingInitialCompletion(true)

        fetch(api + "/ai/plan/", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                topic: topic,
                user: auth.currentUser ? auth.currentUser.uid : "none"
            })
        })
            .then(response => response.json()).then(res => {
                if (res.success) {

                    setLoadingInitialCompletion(false)
                    console.log((res.completion))
                    setBlocks()
                    let newBlocks = [];
                    JSON.parse(res.completion).map(c => {
                        newBlocks.push({
                            id: objectId(),
                            tag: c.tag,
                            html: c.html
                        })
                    })
                    setBlocks(newBlocks)

                    setOpenWindow();
                }

            }).catch(err => {
                console.log(err)
                setLoadingInitialCompletion(false)

            })
    }

    const createDraft = () => {
        setLoadingInitialCompletion(true)

        fetch(api + "/ai/draft/", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "text/event-stream"
            },
            body: JSON.stringify({
                documentTitle: title,
                topic: topic,
                user: auth.currentUser ? auth.currentUser.uid : "none"
            })
        })
            .then(async response => {




                const completion = response.body;
                console.log("STREAMING")
                // eslint-disable-next-line no-undef
                const reader = completion.getReader();
                let result = { content: "" };


                while (true) {
                    const { value, done } = await reader.read();

                    let newBlocks = [{
                        id: objectId(),
                        tag: "p",
                        html: ""
                    }]


                    if (new TextDecoder().decode(value).includes("{")) {


                        const regex = /<chunk>\s*({[^]*?})\s*<\/chunk>/g;

                        let match;
                        const matches = [];

                        while (match = regex.exec(new TextDecoder().decode(value))) {
                            const jsonPart = match[1];
                            matches.push(JSON.parse(jsonPart));
                        }



                        if (matches) {
                            matches.map(match => {
                                // Extract the JSON part from each match
                                const jsonPart = match


                                if (jsonPart.content) {
                                    result.content = result.content + jsonPart.content;
                                    //  console.log("Content:", result.content);
                                    // Create a new instance of chatHistory with updated data


                                    const array = result.content.split("\n\n");

                                    console.log(array)

                                    newBlocks = [];

                                    array.map((a, i) => {

                                        newBlocks.push({
                                            id: objectId(),
                                            tag: "p",
                                            html: a
                                        })

                                        newBlocks.push({
                                            id: objectId(),
                                            tag: "p",
                                            html: ""
                                        })

                                    })

                                    console.log(newBlocks)

                                    setBlocks(newBlocks);
                                }
                            });
                        }

                    }



                    // Here, you can parse the received data and update the state as needed
                    if (done) {

                        console.log("DONE STREAMING")
                        setOpenWindow();
                        setLoadingInitialCompletion(false)
                        updateDocument();
                        break;
                    }
                }


            }).catch(err => {
                console.log(err)
                setLoadingInitialCompletion(false)

            })
    }

    useEffect(() => {
        if (props.signedIn) {
            updateDocument();
        }
    }, [title, props.signedIn])

    return (
        <Fragment>
            <ShareModal webVisibility={webVisibility} title={title} docId={props.docId} open={modals.share} setOpen={(o) => {
                let newModals = { ...modals }
                newModals.share = o;
                setModals(newModals)
            }} />
            <div style={{ height: "100vh", maxHeight: "100vh" }} className="flex flex-col w-full">
                <div className="border-b-2 border-gray-100 px-5 h-[64px] shrink-0 flex justify-between items-center">
                    <div className="flex items-center h-10">
                        {props.signedIn && !props.sidebarOpen && <button onClick={() => props.setSidebarOpen(!props.sidebarOpen)}><Bars3Icon className="h-5 w-5 mr-3" /></button>}
                        <p>{title ? title.replace(/&nbsp;/g, " ") : "Untitled"}</p>

                        {(!props.signedIn || (doc && doc.ownerId != auth.currentUser.uid)) &&
                            <div className="rounded-lg h-8 flex items-center justify-center ml-3 bg-gray-100 px-3 text-sm font-medium text-gray-600">
                                SHARED
                            </div>
                        }

                    </div>
                    {props.signedIn && (doc && doc.ownerId == auth.currentUser.uid) && <div className="flex h-10 gap-6 items-center">
                        <div className=" flex items-center">
                            <SparklesIcon className="h-5 w-5 text-violet-500 mr-2" />
                            <p className="font-medium mr-3">Complete</p>
                            <label class="relative inline-flex items-center cursor-pointer">
                                <input type="checkbox" checked={completeEnabled} onChange={() => setCompleteEnabled(!completeEnabled)} class="sr-only peer" />
                                <div class="w-11 h-6 bg-gray-100 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                            </label>
                        </div>
                        <div className="flex items-center gap-2 h-10">
                            <button onClick={() => setPanelOpen("Originality")} className="shrink-0 rounded-full px-4 h-full bg-gray-100 flex items-center gap-2 font-semibold text-md transition hover:bg-gray-200">
                                <img src="/images/icons/blue_check_badge.png" className="h-5 w-5" />
                                <span className="xl:block hidden"> Originality</span>
                            </button>
                            <button onClick={() => setPanelOpen("Chat")} className="shrink-0 rounded-full px-4 h-full bg-gray-100 flex items-center gap-2 font-semibold text-md transition hover:bg-gray-200">
                                <img src="/images/icons/blue_messages.png" className="h-5 w-5" />
                                <span className="xl:block hidden">  Chat</span>
                            </button>
                            <button onClick={() => setPanelOpen("Feedback")} className="shrink-0 rounded-full px-4 h-full bg-gray-100 flex items-center gap-2 font-semibold text-md transition hover:bg-gray-200">
                                <img src="/images/icons/green_document_check.png" className="h-5 w-5" />
                                <span className="xl:block hidden"> Feedback</span>
                            </button>
                        </div>

                        <button className=""><BookmarkIcon onClick={() => {
                            setPanelOpen("Citations")
                        }} className="h-5 w-5" /></button>


                        <button className=""><ArrowUpOnSquareIcon onClick={() => {
                            let newModals = { ...modals }
                            newModals.share = true;
                            setModals(newModals)
                        }} className="h-5 w-5" /></button>


                        <Popover className="relative">
                            <Popover.Button className="flex items-center justify-center h-full outline-none">
                                <button className=""><EllipsisHorizontalIcon className="h-5 w-5" /></button>
                            </Popover.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel className="absolute -right-0 top-full z-10 mt-5 max-w-md overflow-hidden rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5" style={{ width: "200px" }}>


                                    <div className="">
                                        <button onClick={deleteDocument} className="text-sm font-semibold text-red-600 py-4 px-6 hover:bg-gray-100 transition w-full text-left">Delete document</button>
                                    </div>

                                </Popover.Panel>
                            </Transition>
                        </Popover>
                    </div>}

                    {!props.signedIn &&
                        <div className="flex">
                            <a href="/" className=" bg-black rounded-full text-white text-sm font-medium px-5 h-10 flex items-center hover:bg-neutral-800 transition"><SparklesIcon className="h-5 w-5 mr-2 text-white" /> Get {appName}</a>
                        </div>
                    }


                </div>





                {!error &&
                    <div className="flex h-full">
                        <div className="overflow-y-auto relative h-full" style={{ flex: 1 }} id="editor">

                            <div onMouseDown={(e) => {
                                if ((!props.signedIn || (doc && doc.ownerId != auth.currentUser.uid))) {
                                    e.preventDefault();
                                }
                            }} className={`editor px-10 outline-none ${floatingMenuOpen ? "pb-[28rem]" : "pb-40"} `} contentEditable="false">


                                <div className="px-1">
                                    <div className="relative outline-none" >
                                        <ContentEditable
                                            html={title}
                                            onChange={(e) => { setTitle(e.target.value); }}
                                            tag={"p"}
                                            disabled={(!props.signedIn || (doc && doc.ownerId != auth.currentUser.uid))}
                                            className={"pt-10 my-2 outline-none text-4xl font-semibold pb-5"}
                                            style={{ position: "relative", zIndex: 5 }}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    e.preventDefault()
                                                    const nextBlock = document.querySelector(
                                                        `[data-position="${1}"]`
                                                    );
                                                    if (nextBlock) {
                                                        nextBlock.focus();
                                                    }
                                                }
                                            }}

                                        />
                                        {(!title || title.length == 0) && <p contentEditable={false} className="opacity-20 pt-10 my-2 outline-none text-4xl font-semibold pb-5" style={{ position: "absolute", top: 0 }}>Untitled</p>}
                                    </div>

                                    {props.signedIn && (doc && doc.ownerId == auth.currentUser.uid) && loadedDoc && !openWindow && blocks.length == 1 && (blocks[0].html == "" || !blocks[0].html) &&
                                        <button contentEditable={false} onClick={() => setOpenWindow("plan")} className="px-1 my-2 flex items-center text-neutral-400 w-full py-1 rounded-md hover:bg-gray-50 transition">
                                            <SparklesIcon className="h-5 w-5 mr-2" />
                                            <p>Plan your writing</p>
                                        </button>
                                    }

                                    {props.signedIn && (doc && doc.ownerId == auth.currentUser.uid) && loadedDoc && !openWindow && blocks.length == 1 && (blocks[0].html == "" || !blocks[0].html) &&
                                        <button contentEditable={false} onClick={() => setOpenWindow("draft")} className="px-1 my-2 flex items-center text-neutral-400 w-full py-1 rounded-md hover:bg-gray-50 transition">
                                            <PencilIcon className="h-5 w-5 mr-2" />
                                            <p>Write a draft</p>
                                        </button>
                                    }

                                    {openWindow == "plan" && blocks.length == 1 && (blocks[0].html == "" || !blocks[0].html) &&
                                        <div contentEditable={false} className="border border-2 border-gray-200 rounded-lg p-6 rounded-3xl">
                                            <div className="flex items-center mb-6">
                                                <SparklesIcon className="h-5 w-5 mr-2 text-blue-600" />
                                                <p className="text-lg font-semibold">Plan your writing</p>
                                            </div>

                                            <textarea value={topic} onChange={(e) => setTopic(e.target.value)} className="outline-none focus:ring-0 focus:border-none border-none text-lg w-full h-[120px] resize-none mb-8" placeholder="What are you trying to write?" />

                                            {!loadingInitialCompletion && <div className="flex items-center">
                                                <button disabled={!topic} onClick={createPlan} className="disabled:opacity-50 bg-blue-600 rounded-xl text-white text-sm font-medium px-5 h-10 flex items-center hover:bg-blue-500 transition mr-3  font-semibold"><SparklesIcon className="h-5 w-5 mr-2 text-white" /> Create plan</button>
                                                <button onClick={() => setOpenWindow()} className="text-gray-500 font-semibold rounded-xl h-10 px-6 transition text-sm hover:bg-gray-100">Cancel</button>
                                            </div>}

                                            {loadingInitialCompletion && <div className="flex items-center h-10">
                                                <div role="status" className="mr-2">
                                                    <svg aria-hidden="true" class="w-5 h-5 mr-2 text-gray-300 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                    </svg>
                                                    <span class="sr-only">Loading...</span>
                                                </div>

                                                <p>Creating plan...</p>
                                            </div>}
                                        </div>
                                    }

                                    {openWindow == "draft" &&
                                        <div contentEditable={false} className="border border-2 border-gray-200 rounded-lg p-6 rounded-3xl">
                                            <div className="flex items-center mb-6">
                                                <PencilIcon className="h-5 w-5 mr-2 text-blue-600" />
                                                <p className="text-lg font-semibold">Write a draft</p>
                                            </div>

                                            <textarea value={topic} onChange={(e) => setTopic(e.target.value)} className="outline-none focus:ring-0 focus:border-none border-none text-lg w-full h-[120px] resize-none mb-8" placeholder="What are you trying to write?" />

                                            {!loadingInitialCompletion && <div className="flex items-center">
                                                <button disabled={!topic} onClick={createDraft} className="disabled:opacity-50 bg-blue-600 rounded-xl text-white text-sm font-medium px-5 h-10 flex items-center hover:bg-blue-500 transition mr-3  font-semibold"><SparklesIcon className="h-5 w-5 mr-2 text-white" /> Create draft</button>
                                                <button onClick={() => setOpenWindow()} className="text-gray-500 font-semibold rounded-xl h-10 px-6 transition text-sm hover:bg-gray-100">Cancel</button>
                                            </div>}

                                            {loadingInitialCompletion && <div className="flex items-center h-10">
                                                <div role="status" className="mr-2">
                                                    <svg aria-hidden="true" class="w-5 h-5 mr-2 text-gray-300 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                    </svg>
                                                    <span class="sr-only">Loading...</span>
                                                </div>

                                                <p>Creating draft...</p>
                                            </div>}
                                        </div>
                                    }
                                </div>
                                {blocks.map(block => {
                                    const position =
                                        blocks.map((b) => b.id).indexOf(block.id) + 1;
                                    return (
                                        <Block
                                            key={block.id}
                                            id={block.id}
                                            tag={block.tag}
                                            html={block.html}
                                            position={position}
                                            addBlock={addBlock}
                                            updateBlock={updateBlock}
                                            deleteBlock={deleteBlock}
                                            completeEnabled={completeEnabled}
                                            disabled={(!props.signedIn || (doc && doc.ownerId != auth.currentUser.uid))}
                                            undo={undo}
                                            blocks={blocks}
                                            merge={merge}
                                            setMerge={setMerge}
                                            isRedoing={isRedoing}
                                            setIsRedoing={setIsRedoing}
                                            documentTitle={title}
                                            selectedBlocks={selectedBlocks}
                                            setSelectedBlocks={setSelectedBlocks}
                                            focusBlockAtIndex={focusBlockAtIndex}
                                            setIsTyping={setIsTyping}
                                            setIsTypingForHistory={setIsTypingForHistory}
                                            setFloatingMenuOpen={setFloatingMenuOpen}
                                            setCaretOffset={setCaretOffset}
                                            auth={auth}
                                            setLoadingInitialCompletion={setLoadingInitialCompletion}
                                        />
                                    )
                                })}

                                {/*blocks.length > 1 && <div className="w-full h-[24px] cursor-text"
                                    onClick={() => {
                                        const updatedBlocks = [...blocks];

                                        const newBlock = {
                                            id: objectId(),
                                            tag: "p",
                                            html: ""
                                        }

                                        updatedBlocks.push(newBlock);
                                        setBlocks(updatedBlocks);
                                        setCurrentBlockId(newBlock.id)
                                    }}
                                />*/}

                                {((!props.signedIn || (doc && doc.ownerId != auth.currentUser.uid))) &&

                                    <div className="pt-10 px-1">
                                        <p className="text-3xl font-semibold mb-3">Citations</p>
                                        <div className="flex flex-col gap-2">
                                            {citations.map((c, i) => {
                                                return (
                                                    <div key={i} className="relative rounded-lg hover:bg-gray-100 transition group flex justify-between py-1">
                                                        <ContentEditable disabled={!props.signedIn || (doc && doc.ownerId != auth.currentUser.uid)} html={c} onChange={(e) => { let newCitations = [...citations]; newCitations[i] = e.target.value; setCitations(newCitations) }}
                                                            className="outline-none focus:ring-0 focus:border-none  border-none flex-1 " />
                                                        {props.signedIn && (doc && doc.ownerId == auth.currentUser.uid) && loadedDoc && <button onClick={() => {
                                                            // delete citation
                                                            let newCitations = [...citations];
                                                            newCitations.splice(i, 1);
                                                            setCitations(newCitations)
                                                        }} className="hidden group-hover:block shrink-0 mr-2">
                                                            <XMarkIcon className="h-5 w-5 text-gray-400" />
                                                        </button>}
                                                        {c.length == 0 &&
                                                            <p className="text-gray-400 absolute top-0 flex items-center h-full">Empty citation</p>
                                                        }
                                                    </div>
                                                )
                                            })}

                                            {props.signedIn && (doc && doc.ownerId == auth.currentUser.uid) && loadedDoc && <button onClick={() => {
                                                // add citation then focus on it
                                                let newCitations = [...citations];
                                                newCitations.push("");
                                                setCitations(newCitations)

                                            }} className="relative rounded-lg hover:bg-gray-100 transition group flex items-center gap-2 py-1">
                                                <PlusIcon className="h-5 w-5 text-gray-400" />
                                                <p className="text-gray-400">Add citation</p>
                                            </button>}
                                        </div>

                                    </div>

                                }


                            </div>

                        </div>

                        {auth.currentUser && panelOpen &&
                            <div className="h-full flex flex-col w-[400px] max-w-[400px] border-l-2 border-gray-100">

                                {panelOpen == "Originality" && <OriginalityPanel
                                    setPanelOpen={setPanelOpen}
                                    blocks={blocks}
                                    isTyping={isTyping}
                                />}

                                {panelOpen == "Chat" && <ChatPanel
                                    setPanelOpen={setPanelOpen}
                                    blocks={blocks}
                                    isTyping={isTyping}
                                    citations={citations}
                                    setCitations={setCitations}
                                />}

                                {panelOpen == "Citations" &&

                                    <div className="h-full  ">
                                        <div className="sticky top-0 bg-white flex items-center justify-between gap-2 h-[64px] px-4 border-b-2 border-gray-100 z-[100]">


                                            <div className="flex items-center gap-2">
                                                <p className="font-semibold text-lg">Citations</p>
                                            </div>


                                            <button onClick={() => setPanelOpen(false)}>
                                                <XMarkIcon className="h-6 w-6 text-gray-400 transition hover:text-gray-300" />
                                            </button>

                                        </div>

                                        <div className="py-3 px-3 h-[calc(100dvh-130px)] overflow-y-scroll">

                                            <div className="flex flex-col gap-2">
                                                {citations.map((c, i) => {
                                                    return (
                                                        <div key={i} className="relative px-2 rounded-lg hover:bg-gray-100 transition group flex justify-between py-1">
                                                            <ContentEditable disabled={!props.signedIn || (doc && doc.ownerId != auth.currentUser.uid)} html={c} onChange={(e) => { let newCitations = [...citations]; newCitations[i] = e.target.value; setCitations(newCitations) }}
                                                                className="outline-none focus:ring-0 focus:border-none  border-none flex-1 " />
                                                            {props.signedIn && (doc && doc.ownerId == auth.currentUser.uid) && loadedDoc && <button onClick={() => {
                                                                // delete citation
                                                                let newCitations = [...citations];
                                                                newCitations.splice(i, 1);
                                                                setCitations(newCitations)
                                                            }} className="hidden group-hover:block shrink-0 mr-2">
                                                                <XMarkIcon className="h-5 w-5 text-gray-400" />
                                                            </button>}
                                                            {c.length == 0 &&
                                                                <p className="text-gray-400 absolute top-0 flex items-center h-full">Empty citation</p>
                                                            }
                                                        </div>
                                                    )
                                                })}

                                                {props.signedIn && (doc && doc.ownerId == auth.currentUser.uid) && loadedDoc && <button onClick={() => {
                                                    // add citation then focus on it
                                                    let newCitations = [...citations];
                                                    newCitations.push("");
                                                    setCitations(newCitations)

                                                }} className="relative  px-2 rounded-lg hover:bg-gray-100 transition group flex items-center gap-2 py-1">
                                                    <PlusIcon className="h-5 w-5 text-gray-400" />
                                                    <p className="text-gray-400">Add citation</p>
                                                </button>}
                                            </div>

                                        </div>

                                    </div>
                                }

                            </div>
                        }

                    </div>}

                {error && <div className="flex flex-col flex-1 justify-center items-center">
                    <h1 className="text-2xl font-semibold mb-3">We couldn't load this document.</h1>
                    <p>You may not have permission to access it, or it may have been deleted.</p>
                </div>}
            </div>
        </Fragment>
    )
}

export default Document;