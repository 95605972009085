function setCaretToIndex(contentEditableElement, index, source) {
    var range = document.createRange();
    var sel = window.getSelection();

    // Create a 'walker' with the NodeFilter to find the text node
    var walker = document.createTreeWalker(contentEditableElement, NodeFilter.SHOW_TEXT, null, false);
    var node, textNode, nodeIndex;

    // Iterate over each text node
    while(node = walker.nextNode()) {
        textNode = node;
        // If the index is less than the length of the nodeValue,
        // we have found the position in this node where the selection should start
        console.log("Node length", textNode.nodeValue.length)
        console.log("Node value", textNode.nodeValue)
        if(index <= textNode.nodeValue.length) {
            range.setStart(textNode, index);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
            console.log("set caret to position", index, "source:", source)
            return; // We have done our job
        }
        // index is greater than the size of this node, remove the size of this node from the index and continue iterating
        index -= textNode.nodeValue.length;
    }

    // If we have not found the textNode, set to the end of the last one
    range.selectNodeContents(contentEditableElement);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
}

export default setCaretToIndex;