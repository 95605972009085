import { Bars3Icon, DocumentIcon, DocumentPlusIcon } from "@heroicons/react/24/outline";
import { getAuth } from "firebase/auth";
import { useState } from "react";
import { useEffect } from "react";
import { api } from "../../shared/api";
import { appName } from "../../shared/appName";

const Sidebar = (props) => {

    const auth = getAuth();

    const [docs, setDocs] = useState([]);
    const [creatingDoc, setCreatingDoc] = useState(false);

    const createDocument = () => {
        if (auth.currentUser) {
            setCreatingDoc(true)
            auth.currentUser.getIdToken(/* forceRefresh */ true).then(token => {

                fetch(api + "/ai/create-document/?token=" + (auth.currentUser ? token : "none"), {
                    method: 'POST',
                })
                    .then(response => response.json()).then(res => {
                        if (res.success) {
                            window.location.href = "/doc/" + res.id;

                        }
                        setCreatingDoc(false);

                    }).catch(err => {
                        console.log(err)
                        setCreatingDoc(false);
                    })

            }).catch(err => {
                console.log(err)
                setCreatingDoc(false);
            })
        }
    }


    useEffect(() => {
        if (auth.currentUser) {
            auth.currentUser.getIdToken(/* forceRefresh */ true).then(token => {

                fetch(api + "/ai/documents/?token=" + (auth.currentUser ? token : "none"), {
                    method: 'GET',
                })
                    .then(response => response.json()).then(res => {
                        if (res.success) {
                            setDocs(res.data)

                        }

                    }).catch(err => {
                        console.log(err)

                    })

            }).catch(err => {
                console.log(err)

            })
        }
    }, [auth.currentUser])
    return (
        <div className="relative border-r-2 border-gray-100 p-3 flex flex-col" style={{ width: 300, maxWidth: 300, minWidth: 300 }}>
            <div className="flex flex-col" style={{ flex: 1 }}>
                <div className="flex justify-between items-center mb-14 p-3">
                    <div className="flex items-center ">
                        <div className="h-6 w-6 rounded-lg bg-black mr-2" />
                        <p className="text-2xl font-semibold">{appName}</p>
                        <a class="rounded-full py-1 px-3 md:block hidden text-blue-600 border border-blue-600 font-medium text-sm ml-3 ">Beta</a>
                    </div>

                    {props.docId && <button onClick={() => props.setSidebarOpen(!props.sidebarOpen)}><Bars3Icon className="h-5 w-5" /></button>}
                </div>

                <div className="overflow-y-auto fade-out-bottom pb-80 " style={{ maxHeight: 800 }}>
                    <div className="px-3">
                        <button onClick={createDocument} className="flex items-center mb-8 disabled:opacity-50 p-5 bg-gray-100 transition hover:bg-gray-200 w-full rounded-2xl" disabled={creatingDoc}>
                            <DocumentPlusIcon className="h-6 w-6 text-black mr-3" />
                            <p className="text-black font-medium">New document</p>
                        </button>
                    </div>

                    {docs.map(doc => {
                        return (
                            <a href={"/doc/" + doc.id} className="flex items-center gap-3 rounded-xl transition hover:bg-gray-50 px-5 py-3">
                                <img src="/images/icons/black_pages.png" className="h-6 w-6 shrink-0" />
                                <p className="font-medium truncate">{doc.title ? doc.title : "Untitled"}</p>
                            </a>
                        )
                    })}

                </div>
            </div>
            <div className="py-8 absolute bottom-0 px-6">
                <button onClick={() => auth.signOut()} className="font-semibold text-blue-600">Sign out</button>
            </div>

        </div>
    )
}

export default Sidebar;