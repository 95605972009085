import { appName } from "../../shared/appName";

const Footer = () => {
    return (
        <div className="bg-white relative z-[50] w-full  py-10 border-t border-gray-200 ">

            <div className="max-w-7xl px-4 mx-auto">
                <p className="font-semibold text-sm ">&copy; {new Date().getFullYear()} {appName} &nbsp; <span className="font-normal">All rights reserved.</span></p>

            </div>

        </div>
    )
}

export default Footer;