import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import Document from "../../components/Document";
import Sidebar from "../../components/Sidebar";
import { appName } from "../../shared/appName";

const Main = (props) => {
    const { docId } = useParams();
    const [sidebarOpen, setSidebarOpen] = useState(true);
    return (
        <Fragment>
            <div className="md:flex hidden w-full" style={{ height: "100dvh", overflow: "hidden" }}>
                {props.signedIn && sidebarOpen && <Sidebar docId={docId} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />}
                {docId && <Document docId={docId} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} signedIn={props.signedIn} />}

            </div>

            <div className="text-center md:hidden w-full flex flex-col items-center justify-center px-6" style={{ height: "100vh", overflow: "hidden" }}>
            <div className="flex items-center mb-8">
                <div className="h-6 w-6 rounded-lg bg-black mr-2" />
                <p className="text-2xl font-semibold">{appName}</p>
            </div>
                <p>{appName} isn't available on smaller screens right now. Try accessing it from a laptop or desktop computer instead.</p>
                <p className="mt-20 text-gray-400 text-sm">&copy; {new Date().getFullYear()} Notate</p>
            </div>
        </Fragment>
    )
}

export default Main;