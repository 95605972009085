import { ArrowPathIcon, ArrowUturnLeftIcon, CommandLineIcon, StopIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { getAuth } from "firebase/auth";
import { Fragment, useEffect, useRef, useState } from "react";
import { api } from "../../shared/api";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import { ArrowUpOnSquareIcon, ClipboardIcon, ExclamationCircleIcon, GlobeAmericasIcon, MagnifyingGlassIcon, MicrophoneIcon } from "@heroicons/react/24/outline";

const Message = (props) => {

    const [showWorkModalOpen, setShowWorkModelOpen] = useState(false);


    const auth = getAuth();

    const [selectedPreset, setSelectedPreset] = useState();


    const [imageUrl, setImageUrl] = useState();

    const [citations, setCitations] = useState();

    useEffect(() => {

        if ((props.content && typeof props.content === "string" && props.content.includes("<imageurl>"))) {
            setImageUrl(props.content.split("<imageurl>")[1].split("</imageurl>")[0]);
        }

        if ((props.content && typeof props.content === "string" && props.content.includes("<citations>"))) {
            try {
                setCitations(JSON.parse(props.content.split("<citations>")[1].split("</citations>")[0]));
            }
            catch (e) {

            }
        }
    }, [props.content])





    useEffect(() => {
        if (props.functionCall) {


            switch (props.functionCall.name) {


            }
        }
    }, [])

    const filterMessage = (message) => {
        // Capture content between backticks
        const backtickPattern = /```(.*?)```/gs;
        let preservedContents = [];

        // Replace content between backticks with placeholders
        const withPlaceholders = message.replace(backtickPattern, (_, match) => {
            preservedContents.push(match);
            return `%%PLACEHOLDER${preservedContents.length - 1}%%`;
        });

        // Remove <...>...</...> patterns
        const filtered = withPlaceholders.replace(/<[^>]*>[^<]*<\/[^>]*>/g, '');

        // Restore preserved content
        const restored = filtered.replace(/%%PLACEHOLDER(\d+)%%/g, (_, index) => {
            return "```" + preservedContents[parseInt(index)] + "```";
        });

        return restored.split("<citations>")[0];
    }

    const ref = useRef();

    return (
        <Fragment>

            <div className={props.role == "assistant" ? "flex justify-start pb-4" : "flex justify-end pb-4"} >



                <Fragment>
                    {props.role != "function" &&
                        <div className="flex w-full rounded-xl transition p-1 group relative">

                            <div className="block">
                                <div className={"h-6 w-6 bg-gray-100 rounded-full mr-3 "}>
                                    {props.role == "user" && !props.shared && <img src={props.role == "assistant" ? "/images/icons/purple_sparkles.png" : (auth.currentUser.photoURL)} className=" h-full w-full rounded-full" />}
                                    {props.role == "assistant" && <div className={`bg-black h-full w-full rounded-full`} />}
                                </div>
                            </div>

                            <div className="w-full">
                                <p className="mb-1 text-gray-400 text-xs font-semibold">{props.role == "assistant" ? "NOTATE" : ((!props.shared && auth.currentUser.displayName ) ? auth.currentUser.displayName.split(" ")[0].toUpperCase() : "YOU")}</p>

                                <div className="markdown">

                                    {props.content && typeof props.content === "string" &&

                                        <Fragment>

                                            {props.role == "assistant" ?
                                                <div className="relative">
                                                    <ReactMarkdown className={'completion-markdown' + (auth.currentUser && (auth.currentUser.uid == "Jw0s50mJoWV1uTioIRKbvhMRIKE3") ? " elyssia" : "") + ((props.loading && props.index == props.history.length - 1) ? "" : " complete")}
                                                        children={filterMessage(props.content)} remarkPlugins={[remarkGfm]} components={
                                                            {
                                                                h1: ({ node, ...props }) => <h1 className="text-xl font-semibold mb-3" {...props} />,
                                                                h2: ({ node, ...props }) => <h1 className="text-lg font-semibold mb-3" {...props} />,
                                                                h3: ({ node, ...props }) => <h1 className="text-md font-semibold mb-3" {...props} />,
                                                                a: ({ node, ...props }) => <a target="_blank" className="cursor-pointer inline text-black" {...props} />,
                                                                b: ({ node, ...props }) => <p className="my-5 leading-6 font-medium text-sm font-semibold" {...props} />,
                                                                i: ({ node, ...props }) => <i className="my-5 leading-6 font-medium text-sm" {...props} />,
                                                                p: ({ node, ...props }) => <p element="p" className="my-5 first:mt-0 leading-6 font-medium text-sm" {...props} />,
                                                                ul: ({ node, ...props }) => <ul className="my-5 first:mt-0 font-medium text-sm" {...props} />,
                                                                li: ({ node, ...props }) => <li element="li" className="my-2 first:mt-0  list-disc ml-5 font-medium text-sm" {...props} />,
                                                                ol: ({ node, ...props }) => <ol className="my-5 first:mt-0  font-medium text-sm" {...props} />,

                                                            }} />
                                                </div>
                                                :
                                                <p className="my-5 first:mt-0 leading-6 font-medium text-sm whitespace-pre-line	">{filterMessage(props.content)}</p>
                                            }

                                        </Fragment>
                                    }

                                </div>

                                {props.functionCall &&
                                    <Fragment>
                                        {props.functionCall.name == "list_sets" && <p className="mb-3 leading-6 font-medium text-sm">What would you like to be quizzed on?</p>}
                                        {props.functionCall.name == "synonyms" && <p className="mb-3 leading-6 font-medium text-sm">Here are some synonyms for {JSON.parse(props.functionCall.arguments).word}!</p>}
                                        {props.functionCall.name == "browse_webpage" &&
                                            <Fragment>
                                                <div className="block w-fit mt-3">
                                                    <div className="rounded-lg bg-gray-100 px-4 py-2 w-fit flex items-center gap-2 text-sm font-medium">
                                                        <GlobeAmericasIcon className="h-4 w-4 text-black" />
                                                        <p>Browsing webpage</p>
                                                    </div>
                                                </div>
                                            </Fragment>}
                                        {props.functionCall.name == "web_search" &&
                                            <Fragment>
                                                <div className="block w-fit mt-3">

                                                    <div className="rounded-lg bg-gray-100 px-4 py-2 w-fit flex items-center gap-2 text-sm font-medium">
                                                        <MagnifyingGlassIcon className="h-4 w-4 text-black" />
                                                        <p>{JSON.parse(props.functionCall.arguments).query}</p>
                                                    </div>


                                                </div>
                                            </Fragment>}
                                        {props.functionCall.name == "code_interpreter" &&
                                            <Fragment>
                                                <div className="block w-fit mt-3">
                                                    <div className="rounded-full flex items-center p-3 border-2 border-gray-100">
                                                        <div className="relative mx-2 flex items-center justify-center">
                                                            {props.index == props.history.length - 1 && <svg aria-hidden="true" class="w-8 h-8 relative text-white animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                            </svg>}
                                                            {props.index != props.history.length - 1 && <svg aria-hidden="true" class="w-8 h-8 relative text-white animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                            </svg>}
                                                            <CommandLineIcon className="p-1 absolute h-7 w-7 text-purple-500 " />
                                                        </div>
                                                        <div className="pl-2 pr-8">
                                                            <p className="text-sm block font-semibold">Working</p>
                                                            <button className="text-sm block font-medium text-black" onClick={() => setShowWorkModelOpen(true)}>Show code</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>}
                                        {props.functionCall.name == "calculate" &&
                                            <Fragment>
                                                <div className="block w-fit mt-3">
                                                    <div className="rounded-full flex items-center p-3 border-2 border-gray-100">
                                                        <div className="relative mx-2 flex items-center justify-center">
                                                            {props.index == props.history.length - 1 && <svg aria-hidden="true" class="w-8 h-8 relative text-white animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                            </svg>}
                                                            {props.index != props.history.length - 1 && <svg aria-hidden="true" class="w-8 h-8 relative text-white animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                            </svg>}
                                                            <CommandLineIcon className="p-1 absolute h-7 w-7 text-purple-500 " />
                                                        </div>
                                                        <div className="pl-2 pr-8">
                                                            <p className="text-sm block font-semibold">Working</p>
                                                            <button className="text-sm block font-medium text-black" onClick={() => setShowWorkModelOpen(true)}>Show work</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>}
                                    </Fragment>
                                }

                                {imageUrl &&
                                    <img src={imageUrl} className="rounded-xl bg-gray-100 md:h-auto md:w-[60%] h-auto w-full mt-3" />
                                }

                                {citations &&
                                    <div className="mb-2">
                                        <p className="font-semibold text-sm mb-2">Sources</p>
                                        <div className="flex items-center flex-wrap gap-2">
                                            {citations.map((c, i) => {
                                                return (
                                                    <button key={i} onClick={() => {
                                                        props.setCitations([...props.citations ? props.citations : [], `
                                                        ${c.publication}. (${c.year}) ${c.title}. Available at: ${c.url}
                                                        `])

                                                        props.setCitationFeedback(true);

                                                        setTimeout(() => {
                                                            props.setCitationFeedback(false);
                                                        }, 3000)

                                                    }} className="py-2 px-4 text-sm font-semibold rounded-lg bg-blue-100 text-blue-600 transition hover:bg-blue-200">
                                                        {new URL(c.url).hostname}


                                                    </button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }

                            </div>

                            <div className="absolute top-0 right-0 hidden group-hover:flex items-center justify-end ">
                                {!props.loading && props.index != 0 && !props.shared && (props.index == props.history.length - 1) && props.role == "assistant" && <button onClick={() => props.regenerateResponse()} type="button" className=" transition rounded-full px-2 py-2 text-sm font-semibold text-black hover:bg-gray-200 flex items-center" ><ArrowPathIcon className="h-5 w-5 text-gray-400" /></button>}
                                {!props.loading && props.index != 0 && !props.shared && (props.index == props.history.length - 1) && props.role == "assistant" && <button onClick={() => props.undoSend()} type="button" className="transition rounded-full px-2 py-2 text-sm font-semibold text-black hover:bg-gray-200 flex items-center" ><ArrowUturnLeftIcon className="h-5 w-5 text-gray-400" /></button>}

                                {!props.loading && <button onClick={() => navigator.clipboard.writeText(filterMessage(props.content))} type="button" className="transition rounded-full px-2 py-2 text-sm font-semibold text-black hover:bg-gray-200 flex items-center" ><ClipboardIcon className="h-5 w-5 text-gray-400" /></button>}

                            </div>

                        </div>
                    }
                </Fragment>

            </div>

            {!props.shared && !props.functionCall && <Fragment>
                {props.presets && props.presets.length > 0 && (props.index == props.history.length - 1 || selectedPreset) &&
                    <div className="flex md:flex-row flex-wrap flex-col md:items-center mb-5" style={{ maxWidth: "100%" }}>

                        {props.presets && props.presets.map((p, i) => {
                            return (
                                <button disabled={!!selectedPreset} key={i} onClick={() => { setSelectedPreset(p); props.sendPresetMessage(p) }} className={"transition md:mb-3 mb-3 border-2 transition disabled:hover:bg-white hover:bg-gray-100 bg-white rounded-full px-4 py-3 text-sm mr-3 " + (selectedPreset == p ? "border-blue-600 border-2" : "border-gray-200")} style={{ width: "fit-content" }}>
                                    {filterMessage(p)}
                                </button>
                            )
                        })}
                    </div>
                }



            </Fragment>}
        </Fragment >
    )
}

const ChatPanel = (props) => {

    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });


    const [message, setMessage] = useState();

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(false);

    const [conversationId, setConversationId] = useState();

    const defaultHistory = [];

    const [history, setHistory] = useState(defaultHistory)

    const auth = getAuth();

    const ref = useRef(null);


    const [historyModalOpen, setHistoryModalOpen] = useState(false);

    const [settingsModalOpen, setSettingsModalOpen] = useState(false);

    const [loadedConversation, setLoadedConversation] = useState(false);

    const [initial, setInitial] = useState(true)

    const [premiumModalOpen, setPremiumModalOpen] = useState(false);

    const model = "notate-research-4"

    useEffect(() => {
        const timer = setTimeout(() => {
            ref.current?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' })
        }, 500)

        return () => clearTimeout(timer);

    }, [history])


    useEffect(() => {
        console.log(history);


        if (history.length > 0) {
            const lastHistory = history[history.length - 1];
            console.log(lastHistory);

            const { function_call } = lastHistory;

            if (!function_call) {
                return;
            }

            switch (function_call.name) {
                case "browse_webpage": {
                    fetch(api + "/ai/chat/plugin/web-browsing/read?url=" + JSON.parse(function_call.arguments).url)
                        .then(response => response.json())
                        .then(res => {
                            sendMessage("{webpage_content: '" + res.content + "'}", "function", "browse_webpage");

                        }).catch(err => {
                            console.error('Fetch error: ', err);
                        });
                    break;
                }
                case "web_search": {
                    fetch(api + "/ai/chat/plugin/web-search/query?q=" + JSON.parse(function_call.arguments).query)
                        .then(response => response.json())
                        .then(res => {
                            sendMessage("{results: '" + JSON.stringify(res.results) + "'}", "function", "web_search");

                        }).catch(err => {
                            console.error('Fetch error: ', err);
                        });
                    break;
                }
                case "calculate": {
                    fetch(api + "/ai/calculate", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            exp: JSON.parse(function_call.arguments).exp,
                            mode: JSON.parse(function_call.arguments).mode
                        })
                    })
                        .then(response => response.json())
                        .then(res => {
                            sendMessage("{result: '" + JSON.stringify(res.result) + "'}", "function", "calculate");

                        }).catch(err => {
                            console.error('Fetch error: ', err);
                        });
                    break;
                }
            }

        }


    }, [history, loading]);


    const sendMessage = (m, role, name) => {
        setError(false);
        if (!props.shared && !loading) {
            setInitial(false);
            let chatHistory = [...history];
            let gptHistory = [];
            history.map((h, i) => {
                gptHistory.push({
                    role: h.role,
                    content: h.content ? h.content : "Here's the results of your function call.",
                    name: h.name,
                    function_call: h.function_call
                })
            })
            gptHistory.push({
                role: role ? role : "user",
                content: m,
                name: name
            })
            chatHistory.push({
                role: role ? role : "user",
                content: m,
                name: name
            })
            setHistory(chatHistory)

            setMessage("")




            setLoading(true)
            fetchCompletion(gptHistory, chatHistory);


        }
    }

    const stream = true;



    const fetchCompletion = async (gptHistory, cHistory) => {


        let chatHistory = [...cHistory]

        let text = ""
        props.blocks.map(block => {
            text = text + block.html.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ") + "\n"
        })


        try {
            const response = await fetch(api + "/ai/chat?model=" + model, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    ...stream ? { "Accept": "text/event-stream" } : {}
                },
                body: JSON.stringify({
                    messages: gptHistory,
                    user: auth.currentUser.uid,
                    name: auth.currentUser.displayName,
                    language: localStorage.getItem("chat_language") === null ? "English (US)" : localStorage.getItem("chat_language"),
                    plugins: {
                        web_browsing: localStorage.getItem("chat_web_browsing_enabled") == "true" ? true : false,
                        web_search: localStorage.getItem("chat_web_browsing_enabled") == "true" ? true : false,
                        code_interpreter: localStorage.getItem("chat_code_interpreter_enabled") == "true" ? true : false,
                        calculator: localStorage.getItem("chat_calculator_enabled") == "false" ? false : true
                    },
                    stream: stream,
                    documentText: text

                })
            })




            if (stream) {
                const completion = response.body;
                console.log("STREAMING")
                // eslint-disable-next-line no-undef
                const reader = completion.getReader();
                let result = { content: "" };
                chatHistory.push({})

                while (true) {
                    const { value, done } = await reader.read();




                    if (new TextDecoder().decode(value).includes("{")) {


                        const regex = /<chunk>\s*({[^]*?})\s*<\/chunk>/g;

                        let match;
                        const matches = [];

                        while (match = regex.exec(new TextDecoder().decode(value))) {
                            const jsonPart = match[1];
                            matches.push(JSON.parse(jsonPart));
                        }


                        if (matches) {
                            matches.map(match => {
                                // Extract the JSON part from each match
                                const jsonPart = match


                                if (jsonPart.role) {
                                    result.role = jsonPart.role;
                                    //    console.log("Role:", result.role);
                                }
                                if (jsonPart.function_call) {

                                    //    console.log("Function call:", result.function_call);

                                    if (jsonPart.function_call.name) {
                                        result.function_call = { ...result.function_call, name: jsonPart.function_call.name }
                                    }

                                    if (jsonPart.function_call.arguments) {
                                        result.function_call = { ...result.function_call, arguments: (result.function_call.arguments ? result.function_call.arguments : "") + jsonPart.function_call.arguments }
                                    }


                                }
                                if (jsonPart.content) {
                                    result.content = result.content + jsonPart.content;
                                    //  console.log("Content:", result.content);
                                    // Create a new instance of chatHistory with updated data
                                    const updatedChatHistory = [...chatHistory];
                                    updatedChatHistory[updatedChatHistory.length - 1] = result;

                                    // Now, update your state with the updated chat history
                                    setHistory(updatedChatHistory);
                                    ref.current?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' })
                                }
                            });
                        }

                    }



                    // Here, you can parse the received data and update the state as needed
                    if (done) {
                        result = {
                            ...result, content: result.content.replace(/\\\[/g, '\\\\[').replace(/\\\]/g, '\\\\]')
                                .replace(/\\\(/g, '\\\\(').replace(/\\\)/g, '\\\\)')
                        }
                        chatHistory[chatHistory.length - 1] = result;
                        setHistory(chatHistory);
                        setLoading(false);
                        ref.current?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' })
                        break;
                    }
                }
                /*  chatHistory.push(result);
                  setHistory(chatHistory);*/

            } else {

                const completion = await response.json();

                if (completion.error && completion.message.includes("quota")) {


                    setLoading(false)
                    setPremiumModalOpen(true)

                } else {




                    console.log(message)

                    chatHistory.push(completion.data.choices[0].message)
                    setHistory(chatHistory)

                    setLoading(false);
                }

                const timer = setTimeout(() => {
                    ref.current?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' })
                }, 500)

                return () => clearTimeout(timer);

            }

        }
        catch (err) {
            console.log(err)
            setLoading(false);
            setError(true);
        }
    }

    const regenerateResponse = () => {
        let newHistory = [...history];
        newHistory.pop();


        let chatHistory = [...newHistory];
        let gptHistory = [];
        newHistory.map((h, i) => {
            gptHistory.push({
                role: h.role,
                content: h.content ? h.content : "Here's the results of your function call.",
                name: h.name
            })
        })

        setHistory(chatHistory)

        setMessage("")




        setLoading(true)


        fetchCompletion(gptHistory, chatHistory);
    }

    const undoSend = () => {
        let newHistory = [...history];
        if (newHistory[newHistory.length - 2].role == "function") {
            newHistory.pop();
            newHistory.pop();
            newHistory.pop();
            newHistory.pop();
        } else {
            newHistory.pop();
            newHistory.pop();
        }

        setHistory(newHistory);
    }







    const [isListening, setIsListening] = useState(false); // State to hold the listening status
    const [recognition, setRecognition] = useState(null); // State to hold the recognition object

    // Set up the SpeechRecognition instance once when the component is mounted
    useEffect(() => {
        // Check for browser compatibility
        if (!('webkitSpeechRecognition' in window)) {
            alert('Web Speech API is not supported in this browser. Try using Chrome.');
            return;
        }

        // Create a SpeechRecognition instance
        const recognitionInstance = new window.webkitSpeechRecognition();
        recognitionInstance.interimResults = true; // Get results in real-time

        recognitionInstance.onresult = (event) => {
            let interimTranscript = '';

            for (let i = event.resultIndex; i < event.results.length; i++) {
                const speechResult = event.results[i][0].transcript;
                if (event.results[i].isFinal) {
                    setMessage(prevMessage => prevMessage + ' ' + speechResult);
                } else {
                    interimTranscript += speechResult;
                }
            }
        };

        setRecognition(recognitionInstance);
    }, []);

    // Start or stop the SpeechRecognition instance when the isListening state changes
    useEffect(() => {
        if (isListening) {
            if (!message) {
                setMessage("");
            }
            recognition.start();
        } else if (recognition) {
            recognition.stop();
        }
    }, [isListening, recognition]);


    const textAreaRef = useRef(null);

    const calculateHeight = () => {
        if (message) {
            const numberOfLines = (message.match(/\n/g) || []).length + 1; // Add 1 to count the first line
            if (numberOfLines >= 8) {
                return 50 + (8 - 1) * 25;
            } else {
                return 50 + (numberOfLines - 1) * 25; // 50px per line
            }
        } else {
            return 50
        }
    };

    const insertAtCursor = (valueToInsert) => {
        const textarea = textAreaRef.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const updatedText =
            message.slice(0, start) + valueToInsert + message.slice(end);

        setMessage(updatedText);
        setTimeout(() => {
            textarea.selectionStart = textarea.selectionEnd = start + valueToInsert.length;
        }, 0);
    };

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = calculateHeight() + 'px';
        }
    }, [message]);

    const [citationFeedback, setCitationFeedback] = useState(false)


    return (
        <div className="h-full flex flex-col relative">

            {citationFeedback && <div className="absolute w-full flex justify-center bottom-[90px] z-[100] feedback-popup">
                <div className="w-fit py-2 px-4 rounded-md bg-black text-white text-sm">
                    <p>Added to Citations</p>
                </div>
            </div>}
            <div className="shrink-0  bg-white flex items-center justify-between gap-2 h-[64px] px-4 border-b-2 border-gray-100">


                <div className="flex items-center gap-2">
                    <img src="/images/icons/blue_messages.png" className="h-6 w-6" />
                    <p className="font-semibold text-lg">Chat</p>
                </div>


                <button onClick={() => props.setPanelOpen(false)}>
                    <XMarkIcon className="h-6 w-6 text-gray-400 transition hover:text-gray-300" />
                </button>

            </div>

            <div className="h-[calc(100dvh-130px)] relative w-full ">
                <div className="overflow-y-scroll h-[calc(100dvh-128px)] " >
                    <div className="p-4 ">
                        <div className="flex flex-col justify-end">



                            {history.map((h, i) => (
                                <Message key={i} setCitationFeedback={setCitationFeedback} index={i} history={history} role={h.role} content={h.content} functionCall={h.function_call} presets={h.presets} sendPresetMessage={sendMessage} model={model} regenerateResponse={regenerateResponse} undoSend={undoSend} shared={props.shared} loading={loading} citations={props.citations} setCitations={props.setCitations} />
                            ))
                            }

                            {error &&
                                <div className="w-full flex items-center">
                                    <ExclamationCircleIcon className="h-6 w-6 mr-2 text-red-600" />
                                    <p className="text-red-600 font-medium text-sm">Not Delivered</p>
                                </div>
                            }

                            {loading &&
                                <div className="flex justify-start pb-4">
                                    <div className=" bg-gray-50 p-4 rounded-2xl text-sm " style={{ maxWidth: "80%" }}>
                                        <div class="typingIndicatorBubble">
                                            <div class="typingIndicatorBubbleDot bg-gray-400"></div>
                                            <div class="typingIndicatorBubbleDot bg-gray-400"></div>
                                            <div class="typingIndicatorBubbleDot bg-gray-400"></div>
                                        </div>
                                    </div>
                                </div>
                            }



                        </div>
                        <div className="pb-20" />
                        <div ref={ref} />
                    </div>
                </div>

                <div className="absolute bottom-0 pb-4 flex-row items-center w-full  px-4 flex ">



                    <div className="flex items-center flex-1  relative">
                        <form className="w-full p-0 m-0 leading-none" onSubmit={(e) => {
                            if (message && message.replace(/\n/g, '').length > 0) {
                                e.preventDefault()
                                sendMessage(message)
                            }
                        }
                        }>
                            <textarea
                                ref={textAreaRef}
                                disabled={!auth.currentUser || loading || (history.length > 0 && history[history.length - 1].function_call)}
                                value={message}
                                onChange={(e) => {
                                    setMessage(e.target.value)

                                }}
                                onKeyDown={(e) => {
                                    if (e.shiftKey && e.key === 'Enter') {
                                        e.preventDefault();
                                        insertAtCursor("\n");
                                    } else if (e.key === "Enter") {
                                        if (message && message.replace(/\n/g, '').length > 0) {
                                            e.preventDefault()
                                            sendMessage(message)
                                        }
                                    }
                                }}
                                className={" font-medium focus:outline-none transition-[border-radius,height] rounded-xl bg-gray-100 border-none focus:ring-0 h-[50px] hover:h-[130px] focus:h-[130px]    bg-none text-black placeholder:text-gray-500 px-5 pr-24 py-3 w-full resize-none "}
                                style={{ width: "100%" }}
                                placeholder={loading ? "Replying..." : "Type to chat"}
                            />
                        </form>

                        <div className="flex items-center absolute right-0 pr-2">
                            {('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) && <div className="block">
                                <button disabled={loading} onClick={() => setIsListening(prevIsListening => !prevIsListening)} className=" h-[35px] w-[35px] disabled:opacity-50 focus:outline-none focus:ring-0 transition hover:bg-gray-200 transition rounded-full ml-2 flex items-center justify-center" style={{ padding: '0', boxSizing: 'border-box', display: 'flex' }}>
                                    {isListening ? <StopIcon className="text-black h-5 w-5" /> : <MicrophoneIcon className="text-black h-5 w-5" />}
                                </button>
                            </div>}

                            <div className="block">

                            </div>
                        </div>
                    </div>

                </div>



            </div>





        </div>
    )
}

export default ChatPanel;