const getCaretCoordinates = (fromStart = true) => {
  let x, y;
  const isSupported = typeof window.getSelection !== "undefined";
  const selection = window.getSelection();
  if (selection.rangeCount !== 0) {
    const range = selection.getRangeAt(0).cloneRange();
    range.collapse(fromStart ? true : false);
    const rect = range.getClientRects()[0];
    if (rect) {
      x = rect.left;
      y = rect.top + document.getElementById("editor").scrollTop;
    }
  }

  return { x, y };
};

export default getCaretCoordinates;