import { useState, useEffect } from "react";
import { matchSorter } from "match-sorter";
import objectId from "../../shared/objectId";


const MENU_HEIGHT = 176;
const allowedTags = [
    {
        id: "page-title",
        tag: "h1",
        label: "Heading 1",
    },
    {
        id: "heading",
        tag: "h2",
        label: "Heading 2",
    },
    {
        id: "subheading",
        tag: "h3",
        label: "Heading 3",
    },
    {
        id: "paragraph",
        tag: "p",
        label: "Paragraph",
    },
];

const TagSelectorMenu = ({ position, closeMenu, parentId, parentHtml, setParentHtml, parentTag, setParentTag, addBlock, updateBlock }) => {
    const [tagList, setTagList] = useState(allowedTags);
    const [selectedTag, setSelectedTag] = useState(0);
    const [command, setCommand] = useState("");

    // If the tag selector menu is display outside the top viewport,
    // we display it below the block
    const isMenuOutsideOfTopViewport = position.y - MENU_HEIGHT < 0;
    const y = !isMenuOutsideOfTopViewport
        ? position.y - MENU_HEIGHT
        : position.y + MENU_HEIGHT;
    const x = position.x;

    // Filter tagList based on given command
    useEffect(() => {
        setTagList(matchSorter(allowedTags, command, { keys: ["tag"] }));
    }, [command]);

    // Attach listener to allow tag selection via keyboard
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Enter") {
                e.preventDefault();
                handleSelection(tagList[selectedTag].tag);
                closeMenu()
            } else if (e.key === "Tab" || e.key === "ArrowDown") {
                e.preventDefault();
                const newSelectedTag =
                    selectedTag === tagList.length - 1 ? 0 : selectedTag + 1;
                setSelectedTag(newSelectedTag);
            } else if (e.key === "ArrowUp") {
                e.preventDefault();
                const newSelectedTag =
                    selectedTag === 0 ? tagList.length - 1 : selectedTag - 1;
                setSelectedTag(newSelectedTag);
            } else if (e.key === "Backspace") {
                if (command) {
                    setCommand(command.slice(0, -1));
                } else {
                    closeMenu();
                }
            } else {
                setCommand(command + e.key);
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [tagList, selectedTag]);

    const handleSelection = (tag) => {
        if (parentHtml.charAt(0) == "/") {
            setParentTag(tag)
            setParentHtml("")
            updateBlock({ id: parentId, html: "", tag: tag })
        } else {
            addBlock({ id: parentId, html: parentHtml, tag: parentTag }, {
                id: objectId(),
                html: "",
                tag: tag
            })
        }
    }

    return (
        <div
            className={"absolute rounded-2xl mt-10 bg-white shadow-lg ring-1 ring-gray-900/5 p-2"}
            style={{
                top: y,
                left: x,
                width: 200,
                display: tagList.length > 0 ? "flex" : "none",
                zIndex: 6
            }}
            contentEditable={false}
        >
            <div className="w-full">
                {tagList.map((tag, key) => {
                    return (
                        <div
                            key={key}
                            data-tag={tag.tag}
                            className={
                                tagList.indexOf(tag) === selectedTag
                                    ? ["p-2 hover:bg-gray-100 rounded-md w-full", "bg-gray-100"].join(" ")
                                    : "p-2 hover:bg-gray-100 rounded-md w-full"
                            }
                            role="button"
                            tabIndex="0"
                            onClick={() => {
                                handleSelection(tag.tag)
                            }}
                        >
                            {tag.label}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TagSelectorMenu;