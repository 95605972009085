export const getCaretLineNumber = (element) =>  {
    var selection = window.getSelection();
    if (selection.rangeCount > 0) {
        var range = selection.getRangeAt(0);
        var preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        var startNode = document.createElement("span");
        var endNode = document.createElement("span");

        preCaretRange.insertNode(startNode);
        range.insertNode(endNode);
        
        var height = window.getComputedStyle(element).getPropertyValue('line-height').replace('px', '');
        var startTop = startNode.getBoundingClientRect().top - element.getBoundingClientRect().top;
        var endTop = endNode.getBoundingClientRect().top - element.getBoundingClientRect().top;
        
        var startLineNum = Math.round(startTop / height) + 1;  
        var endLineNum = Math.round(endTop / height) + 1;

        // Clean up created nodes
        startNode.parentNode.removeChild(startNode);
        endNode.parentNode.removeChild(endNode);
        
        return {start: startLineNum, end: endLineNum};
    }
    return {start: -1, end: -1};  // Return -1 if there is no selection
}