export const getOffsetOfLine = (element, n) => {
    var lineHeight = parseFloat(window.getComputedStyle(element).getPropertyValue('line-height'));

    // Create a temporary span to mark the start of line N
    var tempSpan = document.createElement('span');

    element.appendChild(tempSpan);
    tempSpan.style.position = 'absolute';
    tempSpan.style.top = (n - 1) * lineHeight + 'px';

    var range = document.createRange();
    var selection = window.getSelection();
    range.selectNodeContents(element);
    range.setEndBefore(tempSpan);

    selection.removeAllRanges();
    selection.addRange(range);

    var offset = selection.toString().length;
    element.removeChild(tempSpan);

    return offset;
}