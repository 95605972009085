import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { api } from '../../shared/api';

export default function ShareModal(props) {
    const cancelButtonRef = useRef(null)

    const auth = getAuth();

    const [webVisbility, setWebVisibility] = useState();

    useEffect(() => {
        console.log("wv", props.webVisibility)
        setWebVisibility((props.webVisibility == "read" || props.webVisibility == "write") ? true : false);
    }, [props.webVisibility])

    const updateVisibility = () => {
        auth.currentUser.getIdToken(/* forceRefresh */ true).then(token => {
            fetch(api + "/ai/update-document-web-visibility/" + props.docId + "/?token=" + (auth.currentUser ? token : "none"), {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    webVisibility: webVisbility == true ? "none" : "read"
                })
            })
                .then(response => response.json()).then(res => {
                    console.log("Updated visibility!")

                }).catch(err => {
                    console.log(err)

                })
        })
    }


    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => props.setOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative pb-5 transform overflow-hidden rounded-2xl bg-white text-left shadow-xl  sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-white px-8 pt-10 flex flex-col self-center text-left">

                                    <h1 className="text-2xl font-semibold mb-10">Share "{props.title}"</h1>

                                    <div className="mb-10 flex justify-between items-center">
                                        <p>Allow anyone with the link to view</p>
                                        <label class="relative inline-flex items-center cursor-pointer">
                                            <input type="checkbox" checked={webVisbility} onChange={() => setWebVisibility(!webVisbility)} onClick={() => updateVisibility()} class="sr-only peer" />
                                            <div class="w-11 h-6 bg-gray-100 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                        </label>
                                    </div>


                                    <input type="text" value={"https://usenotate.com/doc/" + props.docId} className="w-full mb-3 border-gray-300 rounded-md" />
                                    <button onClick={() => {
                                        navigator.clipboard.writeText("https://usenotate.com/doc/" + props.docId)
                                    }} class="mb-3 w-full text-center rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-60">Copy link</button>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
