import React, { createRef, Fragment } from 'react';
import ContentEditable from 'react-contenteditable';
import { api } from '../../shared/api';
import getCaretCoordinates from '../../shared/getCaretCoordinates';
import objectId from '../../shared/objectId';
import ActionMenu from '../ActionMenu';
import TagSelectorMenu from '../TagSelectorMenu';
import setCaretToIndex from '../../shared/setCaretToIndex';
import { getCaretLineNumber } from '../../shared/getCaretLineNumber';
import { getCaretPosition } from '../../shared/getCaretPosition';
import { getNumberOfLines } from '../../shared/getNumberOfLines';
import { getOffsetOfLine } from '../../shared/getOffsetOfLine';
import PromptMenu from '../PromptMenu';

const tagStyles = {
    p: '',
    h1: 'text-2xl font-semibold',
    h2: 'text-xl font-semibold',
    h3: 'text-lg font-semibold',
};

const tagSpacing = {
    p: '',
    h1: 'pt-3',
    h2: 'pt-3',
    h3: 'pt-3',
};

class Block extends React.Component {
    constructor(props) {
        super(props);

        this.CMD_KEY = '/';

        this.state = {
            html: props.html,
            tag: props.tag,
            previousKey: null,
            focus: null,
            completion: null,
            tagSelectorMenuOpen: false,
            tagSelectorMenuPosition: {
                x: null,
                y: null,
            },
            actionMenuOpen: false,
            actionMenuPosition: {
                x: null,
                y: null,
            },
            isTyping: false,
            isCompleting: false,
            selection: null,
            merge: false,
            isRedoing: false,
            promptMenuOpen: false
        };

        this.contentEditable = createRef();
        this.actionMenuRef = createRef();
        this.typingTimer = null;
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevState.isTyping !== this.state.isTyping) {
            const stripped = this.state.html.replace(/<\/?[^>]+(>|$)/g, "");
            if (this.state.focus && !this.state.isTyping && this.state.html.length > 5 && !this.isCompleteSentence(stripped) && this.props.completeEnabled && this.props.tag == "p") {
                this.complete(stripped)



            } else {
                this.setState({ completion: null });
            }
        }



        if (prevState.html !== this.state.html) {
            this.startTypingTimer()
        }

        if (prevState.html !== this.state.html && this.state.merge) {
            console.log("set caret to index due to merge")
            setCaretToIndex(this.contentEditable.current, prevState.html.length);
            this.setState({
                merge: false
            })
        }

        if (prevState.html !== this.state.html && this.state.isRedoing) {
            this.setState({
                isRedoing: false
            })
        }

        if (this.props.html !== this.state.html && !this.isTyping) {
            console.log("merge", this.props.merge)
            if (this.props.merge) {
                let prevHtml = this.state.html;
                this.setState({
                    html: this.props.html,
                    merge: true
                })

                this.props.setMerge(false)
            }
            else if (this.props.isRedoing) {
                let prevHtml = this.state.html;
                this.setState({
                    html: this.props.html,
                    isRedoing: true
                })

                this.props.setIsRedoing(false)
            }
            else {
                this.props.updateBlock({
                    id: this.props.id,
                    html: this.state.html,

                });
            }
        }

        if (this.props.selectedBlocks != prevProps.selectedBlocks) {
            if (!this.props.selectedBlocks.includes(this.props.id)) {

                const block = this.contentEditable.current;

                if (block) {
                    block.setAttribute("contenteditable", true);
                }
            }
        }
    }

    componentDidMount() {
        document.addEventListener('mouseup', this.handleMouseUp);
        this.contentEditable.current.addEventListener("paste", function (e) {
            e.preventDefault();
            var text = e.clipboardData.getData("text/plain");
            document.execCommand("insertHTML", false, text);
        });
        document.addEventListener('selectstart', this.handleSelectStart);
    }

    componentWillUnmount() {
        if (this.typingTimer) {
            clearTimeout(this.typingTimer);
        }

        document.removeEventListener('mouseup', this.handleSelection);
        document.removeEventListener('selectstart', this.handleSelectStart);


    }

    isCompleteSentence = (str) => {
        // Regular expression to match a sentence ending with a full stop, followed by optional whitespace
        // const regex = /.*\.\s*$/;
        //return regex.test(str);
        let newStr = str.replace(/(?:^[\s\u00a0]+)|(?:[\s\u00a0]+$)/g, '')
        console.log("String:", newStr)
        return (newStr.charAt(newStr.length - 1) == "." || newStr.charAt(newStr.length - 2) == ".")
    }

    complete = (stripped) => {
        this.setState({ isCompleting: true, completion: "" })
        fetch(api + "/ai/complete-stream", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "text/event-stream"
            },
            body: JSON.stringify({
                documentTitle: this.props.documentTitle,
                document: stripped,
                user: "none"
            })
        })
            .then(async response => {

                const completion = response.body;
                console.log("STREAMING")
                // eslint-disable-next-line no-undef
                const reader = completion.getReader();
                let result = { content: "" };


                while (true) {
                    const { value, done } = await reader.read();




                    if (new TextDecoder().decode(value).includes("{")) {


                        const regex = /<chunk>\s*({[^]*?})\s*<\/chunk>/g;

                        let match;
                        const matches = [];

                        while (match = regex.exec(new TextDecoder().decode(value))) {
                            const jsonPart = match[1];
                            matches.push(JSON.parse(jsonPart));
                        }


                        if (matches) {
                            matches.map(match => {
                                // Extract the JSON part from each match
                                const jsonPart = match


                                if (jsonPart.content) {
                                    result.content = result.content + jsonPart.content;
                                    //  console.log("Content:", result.content);
                                    // Create a new instance of chatHistory with updated data

                                    this.setState({ completion: result.content })
                                }
                            });
                        }

                    }



                    // Here, you can parse the received data and update the state as needed
                    if (done) {

                        this.setState({ isCompleting: false })
                        break;
                    }
                }



            }).catch(err => {
                console.log(err)
                this.setState({ isCompleting: false })
            })

    }

    startTypingTimer = () => {
        if (this.typingTimer) {
            clearTimeout(this.typingTimer);
        }
        this.setState({ isTyping: true });
        this.props.setIsTyping(true);
        this.props.setIsTypingForHistory(true);
        console.log("is typing")
        this.typingTimer = setTimeout(() => {
            this.setState({ isTyping: false });
            this.props.setIsTyping(false);
            console.log("not typing")
        }, 1500);

        this.typingTimerForHistory = setTimeout(() => {

            this.props.setIsTypingForHistory(false);
            console.log("not typing")
        }, 500);
    }

    getCaretCharacterOffsetWithin = (element) => {
        var caretOffset = 0;
        if (typeof window.getSelection != "undefined") {
            var range = window.getSelection().getRangeAt(0);
            var preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(element);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            caretOffset = preCaretRange.toString().length;
        } else if (typeof document.selection != "undefined" && document.selection.type != "Control") {
            var textRange = document.selection.createRange();
            var preCaretTextRange = document.body.createTextRange();
            preCaretTextRange.moveToElementText(element);
            preCaretTextRange.setEndPoint("EndToEnd", textRange);
            caretOffset = preCaretTextRange.text.length;
        }
        return caretOffset;
    }



    handleKeyDown = (e) => {
        if (this.props.disabled) {
            e.preventDefault();
            return;
        }
        document.getElementById("ce-" + this.props.id).setAttribute("contenteditable", true);

        console.log("Caret Offset", this.getCaretCharacterOffsetWithin(document.getElementById("ce-" + this.props.id)));
        console.log("Post regex html length", this.state.html.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ").length);

        console.log(e.key)

        if (
            e.key === 'Enter' &&
            this.state.previousKey !== 'Shift' &&
            !this.state.tagSelectorMenuOpen &&
            (this.getCaretCharacterOffsetWithin(document.getElementById("ce-" + this.props.id)) == 0 || this.getCaretCharacterOffsetWithin(document.getElementById("ce-" + this.props.id)) == this.state.html.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ").length)

        ) {
            e.preventDefault();

            this.startTypingTimer();

            if (this.getCaretCharacterOffsetWithin(document.getElementById("ce-" + this.props.id)) == 0 && this.state.html.length > 0) {
                this.props.addBlock(
                    { id: this.props.id, html: this.state.html, tag: this.state.tag },
                    {
                        id: objectId(),
                        html: '',
                        tag: 'p',
                    },
                    -1
                );
            } else {
                this.props.addBlock(
                    { id: this.props.id, html: this.state.html, tag: this.state.tag },
                    {
                        id: objectId(),
                        html: '',
                        tag: 'p',
                    },
                );
            }
        }
        else if (
            e.key === 'Enter' &&
            this.state.previousKey !== 'Shift' &&
            !this.state.tagSelectorMenuOpen
        ) {
            e.preventDefault();
            this.startTypingTimer();
            let firstPart = this.state.html.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ").slice(0, this.getCaretCharacterOffsetWithin(document.getElementById("ce-" + this.props.id)));
            let secondPart = this.state.html.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ").slice(this.getCaretCharacterOffsetWithin(document.getElementById("ce-" + this.props.id)));

            this.setState({ html: firstPart })
            this.props.addBlock({
                id: this.props.id,
                html: firstPart,
                tag: this.state.tag,
            }, {
                id: objectId(),
                html: secondPart,
                tag: this.state.tag
            })
        }
        else if (e.key === 'Backspace' && (this.getCaretCharacterOffsetWithin(document.getElementById("ce-" + this.props.id)) == 0) && (this.props.position != 1 || this.props.blocks.length > 0)) {
            e.preventDefault();
            this.startTypingTimer();
            if (!this.state.html) {
                this.props.deleteBlock({ id: this.props.id, html: this.state.html });
            } else {
                this.props.deleteBlock({ id: this.props.id, html: this.state.html }, -1);
            }
        } else if (((e.key === 'ArrowRight' && this.state.previousKey != "Shift") || e.key === 'Tab') && this.state.completion) {
            e.preventDefault()
            const stripped = this.state.html.replace(/<\/?[^>]+(>|$)/g, "");
            if (stripped.slice(-1) == ";") {
                this.setState({ html: this.state.html + this.state.completion });
            } else {
                this.setState({ html: this.state.html + ' ' + this.state.completion });

            }
            this.setState({ completion: null });
        }
        else if ((((e.ctrlKey || e.metaKey) && (e.key == "J" || e.key == "j"))) && this.state.completion) {
            e.preventDefault()
            const stripped = this.state.html.replace(/<\/?[^>]+(>|$)/g, "");
            this.complete(stripped)
        }
        else if ((e.key == "P" || e.key == "p") && (e.ctrlKey || e.metaKey)) {
            e.preventDefault()
            this.setState({ promptMenuOpen: true })
        }




        else if (e.key === 'ArrowUp') {

            const lineNumber = getCaretLineNumber(this.contentEditable.current).end;
            const caretPosition = this.getCaretCharacterOffsetWithin(this.contentEditable.current)

            console.log("Line number: ", lineNumber);
            console.log("Caret position: ", caretPosition);

            if (lineNumber === 1 && this.props.position > 1) {
                e.preventDefault();


                const prevBlock = document.querySelector(
                    `[data-position="${this.props.position - 1}"]`
                );

                const lineOffset = getOffsetOfLine(prevBlock, Math.round(prevBlock.getBoundingClientRect().height / 24));

                console.log("Line offset: ", lineOffset);

                this.props.focusBlockAtIndex(this.props.position - 1, lineOffset + caretPosition)
            }

        }
        else if (e.key === 'ArrowDown') {
            // e.preventDefault();


            const lineNumber = getCaretLineNumber(this.contentEditable.current).end;
            const caretPosition = this.getCaretCharacterOffsetWithin(this.contentEditable.current);
            const numberOfLines = Math.round(this.contentEditable.current.getBoundingClientRect().height / 24)

            console.log("Line number: ", lineNumber);
            console.log("Caret position: ", caretPosition);
            console.log("Number of lines:", numberOfLines)

            if (lineNumber === numberOfLines && this.props.position < this.props.blocks.length) {
                e.preventDefault();
                this.props.focusBlockAtIndex(this.props.position + 1, caretPosition)
            }

        }
        this.setState({ previousKey: e.key });


    };

    handleKeyUp = (e) => {
        if (this.props.disabled) {
            e.preventDefault();
            return;
        }
        if (e.key === this.CMD_KEY && (this.state.html.length == 1 || (this.state.html.length < 10 && this.state.html.charAt(0) == "/"))) {
            this.openTagSelectorMenu('KEY_CMD');
        }

        if (e.key != "Backspace") {
            this.props.updateBlock({
                id: this.props.id,
                html: this.state.html,
                tag: this.state.tag,
            });
        }

        this.props.setCaretOffset(this.getCaretCharacterOffsetWithin(document.getElementById("ce-" + this.props.id)))

    };

    handleChange = (e) => {
        this.setState({ html: e.target.value });
    };

    getSelection = (element) => {
        let selectionStart, selectionEnd;
        const isSupported = typeof window.getSelection !== "undefined";
        if (isSupported) {
            console.log("is supported")
            const range = window.getSelection().getRangeAt(0);
            const preSelectionRange = range.cloneRange();
            preSelectionRange.selectNodeContents(element);
            preSelectionRange.setEnd(range.startContainer, range.startOffset);
            selectionStart = preSelectionRange.toString().length;
            selectionEnd = selectionStart + range.toString().length;
        }
        return { selectionStart, selectionEnd };
    };


    handleMouseUp = (e) => {
        const block = this.contentEditable.current;
        if (this.state.focus && block) {
            /*    const { selectionStart, selectionEnd } = getSelection(block);
              console.log("get selection")
              if (selectionStart !== selectionEnd) {
                  console.log("Open action menu")
                  this.openActionMenu(block, "TEXT_SELECTION");
              }*/

            const selection = window.getSelection();
            const isTextSelected = !selection.isCollapsed;

            const focusNode = selection.focusNode;
            const focusOffset = selection.focusOffset;

            console.log("Selection:", selection.toString())
            console.log("State selection:", this.state.selection)

            if (isTextSelected && block) {

                const range = selection.getRangeAt(0);
                const rangeIntersectsBlock = range.intersectsNode(block);

                if (rangeIntersectsBlock && !this.state.actionMenuOpen && selection.toString() != " " && this.state.selection != selection.toString()) {
                    // Do something when text is selected
                    console.log('Text is selected, opening action menu');
                    this.setState({ selection: selection.toString() })
                    this.openActionMenu(block, "TEXT_SELECTION");

                }
                //  block.setAttribute("contenteditable", false);
            } else {
                //  block.setAttribute("contenteditable", true);
            }

            //block.setAttribute("contenteditable", true);
        }
    }

    handleMouseDown = (e) => {

        if (this.props.disabled) {
            e.preventDefault();
            return;
        }
        const block = this.contentEditable.current;

        const selection = window.getSelection();
        const isTextSelected = !selection.isCollapsed;

        if (this.actionMenuRef.current && !this.actionMenuRef.current.contains(e.target)) {
            console.log("You clicked outside of action menu!");
            this.closeActionMenu();
        }
    }

    handleMouseMove = (e) => {
        const block = this.contentEditable.current;

        if (block && e.buttons == 1) {

        }
    }

    handleMouseEnter = (e) => {
        const block = this.contentEditable.current;

        if (block && e.buttons == 1) {
            if (!this.props.selectedBlocks.includes(this.props.id)) {
                let newSelectedBlocks = [...this.props.selectedBlocks];
                newSelectedBlocks.push(this.props.id);
                this.props.setSelectedBlocks(newSelectedBlocks)
                block.setAttribute("contenteditable", false);
            } else {
                let newSelectedBlocks = [...this.props.selectedBlocks];
                newSelectedBlocks.pop();
                this.props.setSelectedBlocks(newSelectedBlocks)
                block.setAttribute("contenteditable", true);
            }

        }
    }

    handleMouseLeave = (e) => {
        const block = this.contentEditable.current;

        if (block && e.buttons == 1) {
            if (!this.props.selectedBlocks.includes(this.props.id)) {
                let newSelectedBlocks = [...this.props.selectedBlocks];
                newSelectedBlocks.push(this.props.id);
                this.props.setSelectedBlocks(newSelectedBlocks)
                block.setAttribute("contenteditable", false);
            }
            window.getSelection().removeAllRanges();

        }
    }

    openActionMenu = (parent, trigger) => {
        if (!this.state.actionMenuOpen) {
            const { x, y } = this.calculateActionMenuPosition(parent, trigger);
            this.setState({
                actionMenuPosition: { x: x, y: y },
                actionMenuOpen: true,
            });
            this.props.setFloatingMenuOpen(true);
            const block = this.contentEditable.current;

            if (block) {
                block.setAttribute("contenteditable", true);
            }
        }
        /*  setTimeout(() => {
              document.addEventListener('click', this.closeActionMenu, false);
          }, 100); */
    };

    closeActionMenu = () => {
        this.props.setFloatingMenuOpen(false);

        this.setState({
            actionMenuPosition: { x: null, y: null },
            actionMenuOpen: false,
        });
        /* document.removeEventListener('click', this.closeActionMenu, false); */
    };

    openTagSelectorMenu = (trigger) => {
        const { x, y } = this.calculateTagSelectorMenuPosition(trigger);
        this.setState({
            tagSelectorMenuPosition: { x: x, y: y },
            tagSelectorMenuOpen: true,
        });
        document.addEventListener('click', this.closeTagSelectorMenu, false);
    };

    closeTagSelectorMenu = () => {
        this.setState({
            htmlBackup: null,
            tagSelectorMenuPosition: { x: null, y: null },
            tagSelectorMenuOpen: false,
        });
        document.removeEventListener('click', this.closeTagSelectorMenu, false);
    };

    calculateActionMenuPosition = (parent, initiator) => {
        switch (initiator) {
            case 'TEXT_SELECTION':
                const { x: endX, y: endY } = getCaretCoordinates(false); // fromEnd
                const { x: startX, y: startY } = getCaretCoordinates(true); // fromStart
                const middleX = startX + (endX - startX) / 2;
                console.log({ x: middleX, y: startY })
                return { x: middleX, y: startY };
            case 'DRAG_HANDLE_CLICK':
                const x =
                    parent.offsetLeft - parent.scrollLeft + parent.clientLeft - 90;
                const y = parent.offsetTop - parent.scrollTop + parent.clientTop + 35;
                return { x: x, y: y };
            default:
                return { x: null, y: null };
        }
    };

    calculateToolTipPosition = () => {
        const { x: endX, y: endY } = getCaretCoordinates(false); // fromEnd
        const { x: startX, y: startY } = getCaretCoordinates(true); // fromStart
        const middleX = startX + (endX - startX) / 2;
        console.log({ x: middleX, y: startY })
        return { x: middleX, y: startY };

    };


    calculateTagSelectorMenuPosition = (initiator) => {
        switch (initiator) {
            case 'KEY_CMD':
                const { x: caretLeft, y: caretTop } = getCaretCoordinates(true);
                return { x: caretLeft, y: caretTop };
            case 'ACTION_MENU':
                const { x: actionX, y: actionY } = this.state.actionMenuPosition;
                return { x: actionX - 40, y: actionY };
            default:
                return { x: null, y: null };
        }
    };

    render() {
        return (
            <div className="">


                {!this.props.disabled && this.state.tagSelectorMenuOpen && (
                    <TagSelectorMenu
                        position={this.state.tagSelectorMenuPosition}
                        closeMenu={this.closeTagSelectorMenu}
                        addBlock={this.props.addBlock}
                        parentId={this.props.id}
                        parentHtml={this.state.html}
                        setParentHtml={(html) => this.setState({ html })}
                        parentTag={this.state.tag}
                        setParentTag={(tag) => this.setState({ tag })}
                        updateBlock={this.props.updateBlock}
                    />
                )}
                {!this.props.disabled && this.state.actionMenuOpen && (
                    <ActionMenu
                        position={this.state.actionMenuPosition}
                        actions={{
                            deleteBlock: () => this.props.deleteBlock({ id: this.props.id }),
                        }}
                        selection={this.state.selection}
                        html={this.state.html}
                        setHtml={(html) => { this.setState({ html: html }) }}
                        closeActionMenu={this.closeActionMenu}
                        anchorOffset={this.state.selection.anchorOffset}
                        focusOffset={this.state.selection.focusOffset}
                        updateBlock={this.props.updateBlock}
                        node={this.contentEditable.current}
                        innerRef={this.actionMenuRef}
                    />
                )}
                <div onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    className={`px-1 hover:cursor-text rounded-md relative my-2 w-full transition rounded-md  
                    ${tagSpacing[this.state.tag]}  ${this.props.selectedBlocks.includes(this.props.id) ? " bg-blue-100 " : " "}  
                    ${this.props.selectedBlocks.includes(this.props.id) ? " bg-blue-100" : ""}
                    ${this.state.promptMenuOpen ? " bg-violet-100" : ""}
                    `} 
                    style={{ wordWrap: "break-word", maxWidth: 800, minHeight: 24 }}>
                    {!this.props.disabled && this.state.promptMenuOpen &&
                       
                            <PromptMenu
                                setPromptMenuOpen={(promptMenuOpen) => this.setState({ promptMenuOpen })}
                                parentHtml={this.state.html}
                                setParentHtml={(html) => this.setState({ html })}
                                setLoadingInitialCompletion={this.props.setLoadingInitialCompletion}
                            />
                        
                    }
                    <ContentEditable
                        id={"ce-" + this.props.id}
                        innerRef={this.contentEditable}
                        html={this.state.html}
                        onChange={this.handleChange}
                        tag={this.state.tag}
                        className={'transition rounded-md outline-none ' + tagStyles[this.state.tag]}
                        onKeyDown={this.handleKeyDown}
                        onKeyUp={this.handleKeyUp}
                        onMouseUp={this.handleMouseUp}
                        onMouseDown={this.handleMouseDown}
                        onMouseMove={this.handleMouseMove}
                        disabled={this.props.disabled}
                        style={{
                            position: 'relative',
                            zIndex: 5,
                            display: "inline",
                            width: (!this.state.html || this.state.html.length == 0) ? '200px' : 'fit-content',
                            paddingRight: (!this.state.html || this.state.html.length == 0) ? '600px' : '0px',
                        }}
                        data-position={this.props.position}
                        onFocus={() => this.setState({ focus: true })}
                        onBlur={() => this.setState({ focus: false })}
                    />

                    {this.state.html.length == 0 && this.state.tag != "p" && <span className={"opacity-20 " + tagStyles[this.state.tag]} style={{ display: "inline" }}>&nbsp;</span>}
                    {/*this.state.isCompleting && <p className="opacity-20" style={{ display: "inline" }}>...</p>*/}
                    {this.state.html && this.state.completion && this.state.focus && window.getSelection().isCollapsed && !this.state.isTyping && (
                        <Fragment>
                            <p
                                style={{ display: "inline" }}
                                className={(this.state.html.slice(-1) == ";" || this.state.completion.charAt(4) == ";" || this.state.completion.charAt(0) == " ") ? 'opacity-50 ' + tagStyles[this.state.tag] : 'opacity-50 ml-1 ' + tagStyles[this.state.tag]}
                            >{this.state.completion}
                            </p> <span className="text-blue-600 opacity-100">Press Tab to accept or ⌘+J to regenerate</span></Fragment>
                    )}

                    {((!this.state.html || this.state.html.length === 0)) && this.state.tag && this.state.tag.includes("h") &&
                        (
                            <p
                                className={'opacity-20 ' + tagStyles[this.state.tag] + " " + tagSpacing[this.state.tag]}
                                style={{ position: 'absolute', top: 0, }}
                            >
                                Heading
                            </p>
                        )}
                    {((((!this.state.html || this.state.html.length === 0) && (this.state.focus)) && this.state.tag && this.state.tag == "p")) &&
                        (
                            <p
                                className={'opacity-20 ' + tagStyles[this.state.tag] + " " + tagSpacing[this.state.tag]}
                                style={{ position: 'absolute', top: 0, }}
                            >
                                Start typing, press "/" for commands, or press ⌘+P for AI
                            </p>
                        )}
                </div>
            </div>
        );
    }
}

export default Block;