import { useState } from "react";
import { useRef } from "react";
import { Fragment } from "react";
import { useEffect } from "react";
import { api } from "../../shared/api";
import { SparklesIcon } from "@heroicons/react/24/solid";


const MENU_WIDTH = 400;
const MENU_HEIGHT = 220;

const ActionMenu = ({ position, actions, selection, html, setHtml, closeActionMenu, anchorOffset, focusOffset, updateBlock, node }) => {
  const x = position.x - MENU_WIDTH / 1.2;
  const y = position.y - MENU_HEIGHT + 150;

  const [loading, setLoading] = useState(false);
  const [synonyms, setSynonyms] = useState()
  const [selectionData, setSelectionData] = useState();

  const componentRef = useRef(null);

  useEffect(() => {
    // Close action menu on key down
    function handleKeyDown(event) {

      closeActionMenu();

    }
    // event listener
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      // cleanup
      document.removeEventListener("keydown", handleKeyDown);
    }
  }, [])

  useEffect(() => {

    console.log("Split selection", selection.split(" "))


    let startIndex = anchorOffset;
    let endIndex = focusOffset;
    if (startIndex > endIndex) {
      startIndex = focusOffset;
      endIndex = anchorOffset;
    }

    let cloned = node.cloneNode(true);

    const range = window.getSelection().getRangeAt(0);
    const startOffset = range.startOffset;
    const endOffset = range.endOffset;
    console.log("Offset", startOffset, endOffset)



    setSelectionData({
      startIndex: startIndex,
      endIndex: endIndex,
      startOffset: startOffset,
      endOffset: endOffset,
      range: window.getSelection().getRangeAt(0),
      startContainer: range.startContainer,
      endContainer: range.endContainer
    })

    console.log({
      startIndex: startIndex,
      endIndex: endIndex,
      startOffset: startOffset,
      endOffset: endOffset,
      range: window.getSelection().getRangeAt(0),
      startContainer: range.startContainer,
      endContainer: range.endContainer
    })
  }, [])

  useEffect(() => {
    console.log("Initial render")
  }, [])

  useEffect(() => {


    console.log("Selection: ", selection)
    if ((!selection || selection == "") && !selectionData) {
      closeActionMenu()
      return;
    }

    if (!loading) {
      if (selection && !selection.includes(" ") && !synonyms) {
        setLoading(true);

        fetch(api + "/ai/synonyms", {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            selection: selection,
            user: "none"
          }),
        })
          .then(response => response.json()).then(res => {

            if (res.success) {

              setSynonyms(JSON.parse(res.completion))
              setLoading(false)
            } else {
              setLoading(false)
            }



          }).catch(err => {
            console.log(err)
            setLoading(false)
          })

      }
    }

  }, [selection, loading, synonyms])




  const replaceWithSelectionData = (replacement) => {
    /* const beforeSelection = html.substring(0, selectionData.startIndex);
     const afterSelection = html.substring(selectionData.endIndex, html.length);
     console.log(beforeSelection);
     console.log(afterSelection)*/



    let range = document.createRange();

    range.setStart(selectionData.startContainer, selectionData.startOffset);
    range.setEnd(selectionData.endContainer, selectionData.endOffset);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    console.log(selectionData.startContainer)
    console.log("REPLACED WITH REWRITE")
    // and set the html as you see fit
    //setHtml(`${beforeSelection}${replacement}${afterSelection}`)

    document.execCommand("insertHTML", false, replacement);
  }

  const rewrites = [
    {
      id: "longer",
      label: "Make it longer"
    },
    {
      id: "shorter",
      label: "Make it shorter",
    },
    {
      id: "improve",
      label: "Improve writing"
    },
    {
      id: "simplify",
      label: "Simplify"
    },
    {
      id: "correct",
      label: "Fix spelling and grammar"
    }
  ]
  const [rewritten, setRewritten] = useState();

  const rewriteText = (id) => {
    if (selection && selection.includes(" ")) {

      fetch(api + "/ai/rewrite", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "text/event-stream"
        },
        body: JSON.stringify({
          id: id,
          selection: selection,
          user: "none"
        })
      })
        .then(async response => {
          const completion = response.body;
          console.log("STREAMING")
          // eslint-disable-next-line no-undef
          const reader = completion.getReader();
          let result = { content: "" };


          while (true) {
            const { value, done } = await reader.read();




            if (new TextDecoder().decode(value).includes("{")) {


              const regex = /<chunk>\s*({[^]*?})\s*<\/chunk>/g;

              let match;
              const matches = [];

              while (match = regex.exec(new TextDecoder().decode(value))) {
                const jsonPart = match[1];
                matches.push(JSON.parse(jsonPart));
              }


              if (matches) {
                matches.map(match => {
                  // Extract the JSON part from each match
                  const jsonPart = match


                  if (jsonPart.content) {
                    result.content = result.content + jsonPart.content;
                    //  console.log("Content:", result.content);
                    // Create a new instance of chatHistory with updated data

                    setRewritten(result.content)
                  }
                });
              }

            }



            // Here, you can parse the received data and update the state as needed
            if (done) {
              console.log("DONE STREAMING")
              setLoading(false)
              break;
            }
          }


        }).catch(err => {
          console.log(err)
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    function handleClick(event) {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        closeActionMenu(true)
      }
    }

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [])

  return (
    <div
      className={"absolute down-and-fade-in rounded-xl mt-10 bg-white shadow-xl border border-gray-100 p-5"}
      style={{
        top: y,
        left: x,
        zIndex: 6,
        width: rewritten ? 600 : 400,
      }}
      ref={componentRef}
      contentEditable={false}
    >
      <div>
        {((selection.split(" ").length > 1 && selection.split(" ")[1] != "") || rewritten) && <Fragment>
          <div className="mb-4 gap-2 flex items-center">
            <SparklesIcon className="h-5 w-5 text-violet-500" />
            <p className="text-black font-medium">Rewrite</p>
          </div>

          <div className="w-full">
            {!rewritten && !loading && rewrites.map((rewrite, key) => {
              return (
                <div
                  key={key}

                  className="p-2 hover:bg-gray-100 transition rounded-md w-full"
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    rewriteText(rewrite.id)
                  }}
                >
                  {rewrite.label}
                </div>
              );
            })}

            {loading && <div className="flex flex-col items-center justify-center">
              <div role="status">
                <svg aria-hidden="true" class="mb-3 w-8 h-8 mr-2 text-gray-300 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
              <p className="mb-1 text-sm">Rewriting...</p>

            </div>}
          </div>

          {rewritten && <div><div className="bg-gray-100 rounded-lg p-3 text-sm mb-3 max-h-[310px] overflow-y-auto">
            {rewritten}
          </div>
            <div


              className="p-2 hover:bg-gray-100 rounded-md w-full text-violet-600 transition font-semibold"
              role="button"
              tabIndex="0"
              onClick={() => {
                console.log("Clicked replace")
                replaceWithSelectionData(rewritten);
                closeActionMenu();
              }}
            >
              Replace selection
            </div>
          </div>}
        </Fragment>

        }

        {(selection.split(" ").length == 1 || selection.split(" ")[1] == "")  && !rewritten && <Fragment>
          <div className="mb-3 gap-2 flex items-center">
            <SparklesIcon className="h-5 w-5 text-violet-500" />
            <p className="text-black font-medium">Synonyms</p>
          </div>
          <div className="mt-5 flex flex-wrap">
            {synonyms && synonyms.map(s => {
              return (
                <button onClick={() => { replaceWithSelectionData(s); closeActionMenu() }} className="rounded-md px-4 py-2 bg-violet-100 text-violet-600 transition hover:bg-violet-200 font-semibold mr-2 mb-2">{selection.charAt(0) == selection.charAt(0).toLowerCase() ? s : s[0].toUpperCase() + s.slice(1).toLowerCase()}</button>
              )
            })}
            {!synonyms &&
              <Fragment>
                <div className="rounded-md px-4 py-2 bg-gray-100 text-gray-100 font-semibold mr-2 mb-2 animate-pulse">This</div>
                <div className="rounded-md px-4 py-2 bg-gray-100 text-gray-100 font-semibold mr-2 mb-2 animate-pulse">is a</div>
                <div className="rounded-md px-4 py-2 bg-gray-100 text-gray-100 font-semibold mr-2 mb-2 animate-pulse">blank</div>
                <div className="rounded-md px-4 py-2 bg-gray-100 text-gray-100 font-semibold mr-2 mb-2 animate-pulse">loading</div>
                <div className="rounded-md px-4 py-2 bg-gray-100 text-gray-100 font-semibold mr-2 mb-2 animate-pulse">placeholder</div>
                <div className="rounded-md px-4 py-2 bg-gray-100 text-gray-100 font-semibold mr-2 mb-2 animate-pulse">hahaha</div>
              </Fragment>
            }
          </div></Fragment>}
      </div>
    </div>
  );
};

export default ActionMenu;